import {makeStyles} from 'tss-react/mui';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useState} from 'react';
import {useMySettings} from '_contexts/MySettingsProvider';
import axios from 'axios';
import {getLFCData} from '_logics/LFCUtil';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import {GridValueFormatterParams} from '@mui/x-data-grid';
import {fmtYYYYMMDDHHmmss} from '_logics/LFCFormatUtil';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      '& div': {
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }
    },
    search: {
      display: 'flex',
      alignItems: 'start',
      // margin: "10px 0px 0px",
      '& > *': {
        margin: theme.spacing(1)
        // width: "20ch",
      }
    },
    tableLine: {
      '& th': {
        border: 'solid 1px silver'
      },
      '& tr': {
        border: 'solid 1px silver'
      },
      '& td': {
        border: 'solid 1px silver'
      }
    },
    tableLineDark: {
      '& th': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& tr': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& td': {
        border: 'solid 1px rgb(40,100,100)'
      }
    }
  };
});

interface Props {
  pageName: string;
  open: boolean;
  onClose: () => void;
  datas: any;
  searchValue: any;
}

export const useCloudDistanceDig = (props: Props) => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const mySettings = useMySettings();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [graphBaseData, setGraphBaseData] = useState<any>([]);
  const [listData, setListData] = useState<any>([]);

  const CloudDistanceDetailColumn: {
    field: string;
    headerName: string;
    description: string;
    sortable: boolean;
    width: number;
    valueFormatter?: (prms: GridValueFormatterParams) => string | undefined;
  }[] = [
    {
      field: 'id',
      headerName: t('連番'),
      description: t('連番'),
      sortable: false,
      width: 100
    },
    {
      field: 'uuid',
      headerName: t('シリアルNo.'),
      description: t('ワークごとの識別子'),
      sortable: false,
      width: 200
    },
    {
      field: 'map',
      headerName: t('検査部位'),
      description: t('検査部位の識別子'),
      width: 100,
      sortable: false
    },
    {
      field: 'lq_time',
      headerName: t('検査日時'),
      description: t('LQを実施した日時'),
      width: 200,
      sortable: false,
      valueFormatter: (prms: GridValueFormatterParams) => fmtYYYYMMDDHHmmss(prms.value)
    },
    {
      field: 'upper_distance',
      headerName: t('距離 最大値'),
      description: t('距離 最大値'),
      width: 200,
      sortable: false
    },
    {
      field: 'lower_distance',
      headerName: t('距離 最小値'),
      description: t('距離 最小値'),
      width: 200,
      sortable: false
    },
    {
      field: 'upper_limit',
      headerName: t('上限'),
      description: t('上限'),
      width: 200,
      sortable: false
    },
    {
      field: 'lower_limit',
      headerName: t('下限'),
      description: t('下限'),
      width: 200,
      sortable: false
    }
  ];
  const [searchValue, setSearchValue] = useState<{
    select_date_from: string;
    select_date_to: string;
    work: string[];
    deviceid: string[];
    judge: number;
    map: string[];
    searchType: number;
  }>({
    select_date_from: props.searchValue.select_date_from,
    select_date_to: props.searchValue.select_date_to,
    work: [],
    deviceid: [],
    judge: 9,
    map: props.datas.mapName ? [props.datas.mapName] : props.datas.map,
    searchType: 2
  });
  useEffect(() => {
    if (!props.open) return;
    const propsSearchValue = {
      ...searchValue,
      map: props.datas.mapName ? [props.datas.mapName] : props.datas.map
    };
    setSearchValue(propsSearchValue);
  }, [props.open]);

  const searchType: any = [
    {id: 0, text: t('検査ごと')},
    {id: 1, text: t('1時間ごと')},
    {id: 2, text: t('1日ごと')},
    {id: 3, text: t('1ヶ月ごと')}
  ];

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    if (!props.open) return;
    setListData([]);
    setSearchValue({
      ...searchValue,
      select_date_from: props.searchValue.select_date_from,
      select_date_to: props.searchValue.select_date_to,
      work: props.searchValue.work,
      deviceid: props.searchValue.deviceid,
      map: props.datas.mapName ? [props.datas.mapName] : props.datas.map,
      judge: props.searchValue.judge,
      searchType: props.searchValue.searchType
    });
  }, [props]);

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  const onChangeRadio = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, searchType: Number(e.target.value)});
  };

  const doSearch = async () => {
    setListData([]);

    setStartProcess(true);
    const response = await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 30003,
      parameters: {
        select_date_from: searchValue.select_date_from,
        select_date_to: searchValue.select_date_to,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
        deviceid: !isEmpty(searchValue.deviceid)
          ? '{' + searchValue.deviceid.join(',') + '}'
          : null,
        judge: searchValue.judge === 9 ? null : searchValue.judge,
        map: Array.isArray(searchValue.map) ? searchValue.map[0] : searchValue.map
      },
      name: 'id-30003',
      cancelToken: source.token,
      t
    });
    setListData(
      response.map((e: any) => {
        return {
          ...e,
          id: e.idx
        };
      })
    );
    setStartProcess(false);
  };

  const date_calc = useCallback(() => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_date_from);
    let to_day = new Date(searchValue.select_date_to);

    switch (searchValue.searchType) {
      case 0:
        return [];
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
    }
  }, [startProcess]);

  const restSearch = () => {
    setStartProcess(false);
    setSearchValue({
      ...searchValue,
      select_date_from: props.searchValue.select_date_from,
      select_date_to: props.searchValue.select_date_to,
      work: props.searchValue.work,
      deviceid: props.searchValue.deviceid,
      map: [props.datas.mapName],
      judge: props.searchValue.judge,
      searchType: props.searchValue.searchType
    });
    setAutoCompleteReset(true);
  };

  const [zoomChartOpen, setZoomChartOpen] = useState<string>('');

  const [detailListOpen, setDetailListOpen] = useState(false);

  const listDigClose = () => {
    setDetailListOpen(false);
  };

  const toLocalStringEx = (params: number) => {
    return Number(params).toLocaleString();
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState<boolean>(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  const [map, setMap] = useState<{label: string}[]>([]);
  useEffect(() => {
    if (!props.open) return;
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42009,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['work']};
        });
        setWork(tmp);
      })
      .catch(e => e);

    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 30004,
      parameters: {
        select_date_from: searchValue.select_date_from,
        select_date_to: searchValue.select_date_to,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
        deviceid: !isEmpty(searchValue.deviceid)
          ? '{' + searchValue.deviceid.join(',') + '}'
          : null,
        judge: searchValue.judge === 9 ? null : searchValue.judge
      },
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['map']};
        });
        setMap(tmp);
      })
      .catch(e => e);

    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42010,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['deviceid']};
        });
        setDeviceid(tmp);
      })
      .then(() => setStartProcess(false))
      .catch(e => e);

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, [props]);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  useEffect(() => {
    setStartProcess(true);
    let tmp_all: any = [];
    let tmp_data_upper_distance: any = [];
    let tmp_data_lower_distance: any = [];
    let tmp_data_upper_limits: any = [];
    let tmp_data_lower_limits: any = [];
    const map_string = Array.isArray(searchValue.map) ? searchValue.map[0] : searchValue.map;
    if (searchValue.searchType !== 0) {
      date_calc().forEach((lq_time_string: string) => {
        let tmp_upper_distance: any = [];
        let tmp_lower_distance: any = [];
        let tmp_upper_limits: any = [];
        let tmp_lower_limits: any = [];
        switch (searchValue.searchType) {
          case 0:
            listData.forEach((item: any) => {
              if (item.map === map_string) {
                tmp_upper_distance.push(Number(item['upper_distance']));
                tmp_lower_distance.push(Number(item['lower_distance']));
                tmp_upper_limits.push(Number(item['upper_limit']));
                tmp_lower_limits.push(Number(item['lower_limit']));
              }
            });
            break;
          case 1:
            listData.forEach((item: any) => {
              if (item.map === map_string && item.year_month_day_hour === lq_time_string) {
                tmp_upper_distance.push(Number(item['upper_distance']));
                tmp_lower_distance.push(Number(item['lower_distance']));
                tmp_upper_limits.push(Number(item['upper_limit']));
                tmp_lower_limits.push(Number(item['lower_limit']));
              }
            });
            break;
          case 2:
            listData.forEach((item: any) => {
              if (item.map === map_string && item.year_month_day === lq_time_string) {
                tmp_upper_distance.push(Number(item['upper_distance']));
                tmp_lower_distance.push(Number(item['lower_distance']));
                tmp_upper_limits.push(Number(item['upper_limit']));
                tmp_lower_limits.push(Number(item['lower_limit']));
              }
            });
            break;
          case 3:
            listData.forEach((item: any) => {
              if (item.map === map_string && item.year_month === lq_time_string) {
                tmp_upper_distance.push(Number(item['upper_distance']));
                tmp_lower_distance.push(Number(item['lower_distance']));
                tmp_upper_limits.push(Number(item['upper_limit']));
                tmp_lower_limits.push(Number(item['lower_limit']));
              }
            });
            break;
        }
        tmp_data_upper_distance.push(tmp_upper_distance);
        tmp_data_lower_distance.push(tmp_lower_distance);
        tmp_data_upper_limits.push(tmp_upper_limits);
        tmp_data_lower_limits.push(tmp_lower_limits);
      });
      const latestData =
        listData.length > 0
          ? listData
              .filter((a: any) => a.map === map_string)
              .reduce(
                (
                  latest: {lq_time: string | number | Date},
                  current: {lq_time: string | number | Date}
                ) => {
                  return new Date(latest.lq_time) > new Date(current.lq_time) ? latest : current;
                }
              )
          : null;
      const max = latestData ? parseFloat(latestData.upper_limit.toFixed(2)) : 0;
      const min = latestData ? parseFloat(latestData.lower_limit.toFixed(2)) : 0;
      const upperLimits = new Set(listData.map((item: {upper_limit: any}) => item.upper_limit));
      const lowerLimits = new Set(listData.map((item: {lower_limit: any}) => item.lower_limit));

      const hasMultipleLimit = upperLimits.size > 1 || lowerLimits.size > 1;

      tmp_all.push({
        uuid: listData.filter((a: any) => a.map === map_string)[0]?.uuid,
        lq_time: listData.filter((a: any) => a.map === map_string)[0]?.lq_time,
        map: map_string,
        data_upper_distance: tmp_data_upper_distance,
        data_lower_distance: tmp_data_lower_distance,
        data_upper_limits: tmp_data_upper_limits,
        data_lower_limits: tmp_data_lower_limits,
        xlist: date_calc(),
        upper_limit: max,
        lower_limit: min,
        hasMultipleLimit
      });
    } else {
      const latestData =
        listData.length > 0
          ? listData
              .filter((a: any) => a.map === map_string)
              .reduce(
                (
                  latest: {lq_time: string | number | Date},
                  current: {lq_time: string | number | Date}
                ) => {
                  return new Date(latest.lq_time) > new Date(current.lq_time) ? latest : current;
                }
              )
          : null;
      const max = latestData ? parseFloat(latestData.upper_limit.toFixed(2)) : 0;
      const min = latestData ? parseFloat(latestData.lower_limit.toFixed(2)) : 0;
      const upperLimits = new Set(listData.map((item: {upper_limit: any}) => item.upper_limit));
      const lowerLimits = new Set(listData.map((item: {lower_limit: any}) => item.lower_limit));

      const hasMultipleLimit = upperLimits.size > 1 || lowerLimits.size > 1;
      tmp_all.push({
        uuid: listData.filter((a: any) => a.map === map_string)[0]?.uuid,
        lq_time: listData.filter((a: any) => a.map === map_string)[0]?.lq_time,
        map: map_string,
        data_upper_distance: listData
          .filter((a: any) => a.map === map_string)
          .map((b: any) => Number(b.upper_distance)),
        data_lower_distance: listData
          .filter((a: any) => a.map === map_string)
          .map((b: any) => Number(b.lower_distance)),
        data_upper_limits: listData
          .filter((a: any) => a.map === map_string)
          .map((b: any) => Number(b.upper_limit)),
        data_lower_limits: listData
          .filter((a: any) => a.map === map_string)
          .map((b: any) => Number(b.lower_limit)),
        xlist: listData.filter((a: any) => a.map === map_string).map((b: any) => b.idx),
        upper_limit: max,
        lower_limit: min,
        hasMultipleLimit
      });
    }
    setGraphBaseData(tmp_all);
    setStartProcess(false);
  }, [listData]);
  return {
    startProcess,
    t,
    classes,
    searchValue,
    setSearchValue,
    handleChange,
    work,
    map,
    deviceid,
    autoOnChange,
    autoCompleteReset,
    setAutoCompleteReset,
    doSearch,
    restSearch,
    setDetailListOpen,
    searchType,
    onChangeRadio,
    listData,
    graphBaseData,
    zoomChartOpen,
    setZoomChartOpen,
    date_calc,
    mySettings,
    toLocalStringEx,
    detailListOpen,
    listDigClose,
    CloudDistanceDetailColumn
  };
};
