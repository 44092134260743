import {Dialog, DialogContent, IconButton, Stack, Theme, useTheme} from '@mui/material';
import ReactEcharts from 'echarts-for-react';
import {clickButtonFromChart} from '_components/ExportExcelCsv';
import {useMySettings} from '_contexts/MySettingsProvider';
import '_themes/echarts';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import {makeStyles} from '@mui/styles';

//infographic
//roma
//shine
//vintage
//macarons

/**
 * スタイル
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0)
  }
}));

/**
 * 引数
 */
interface Props {
  option: any;
  onEvents?: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  graphic?: any;
  height?: string;
  style?: any;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomStatus?: boolean;
  isShowDetailAnalysis?: boolean;
  onClickDetailAnalysis?: any;
  inputScaleStatus?: boolean; // スケール変更有無
  inputScaleOpen?: () => void; // スケール変更押下時処理
  inputScaleApply?: () => void; // スケール変更確定時処理
}

/**
 * LFCBaseCharts
 * @param props
 * @returns
 */
const LFCBaseCharts = (props: Props) => {
  const {t} = useTranslation();
  const {i18n} = useTranslation();
  const mySettings = useMySettings();
  const myTheme = useTheme();
  const classes = useStyles();

  // アニメーション制御のチャートの判定
  let fixAnimeChart: boolean = false;
  if (
    props.option.title !== undefined &&
    (props.option.title.text === '検査数と不良率' || props.option.title.text === '生産数と不良率')
  ) {
    fixAnimeChart = true;
  }

  //optionの取り込み
  let options: any = {
    toolbox: {
      show: true,
      showTitle: false,
      feature: {
        myDetailAnalysis: {
          show: props.isShowDetailAnalysis ? true : false,
          title: t('詳細分析'),
          icon:
            'path://M107.094,411.812h80.633c5.66,0,10.246-4.25,10.246-9.496V282.598c0-5.246-4.586-9.5-10.246-9.5h-80.633\n' +
            'c-5.658,0-10.246,4.254-10.246,9.5v119.719C96.848,407.562,101.436,411.812,107.094,411.812z\n' +
            'M254.108,236.082c-5.66,0-10.248,4.254-10.248,9.496v156.738c0,5.246,4.588,9.496,10.248,9.496h80.631\n' +
            'c5.66,0,10.248-4.25,10.248-9.496V245.578c0-5.242-4.588-9.496-10.248-9.496H254.108z\n' +
            'M390.873,178.426v223.891c0,5.246,4.588,9.496,10.246,9.496h80.633c5.658,0,10.248-4.25,10.248-9.496V178.42\n' +
            'c0-5.246-4.59-9.496-10.248-9.496h-80.633C395.461,168.93,390.873,173.18,390.873,178.426z',

          onclick: function () {
            props.onClickDetailAnalysis();
          }
        },
        myInputScale: {
          show: props.inputScaleStatus !== undefined ? props.inputScaleStatus : false,
          title: t('スケール変更'),
          icon:
            'path://M373.324,0.003L0,373.321l138.676,138.676L512,138.68L373.324,0.003z M42.668,373.321l43.942-43.95\n' +
            'l49.436,49.437l18.671-18.664l-49.437-49.437l37.38-37.379l28.482,28.475l18.664-18.664l-28.475-28.482l37.328-37.328\n' +
            'l49.437,49.437l18.664-18.664l-49.437-49.437l37.394-37.394l28.475,28.482l18.664-18.664l-28.475-28.482l37.328-37.336\n' +
            'l49.437,49.436l18.672-18.664l-49.437-49.437l43.942-43.942l96.008,96.015L138.676,469.337L42.668,373.321z',
          onclick: function () {
            props.inputScaleOpen !== undefined && props.inputScaleOpen();
          }
        },

        myZoom: {
          show: props.zoomStatus === false,
          title: t('拡大'),
          icon:
            'path://M311.84,216.957h-52.016v-52.031c0-11.828-9.609-21.422-21.438-21.422s-21.422,9.594-21.422,21.422v52.031\n' +
            'h-52.031c-11.844,0-21.438,9.594-21.438,21.438c0,11.828,9.594,21.422,21.438,21.422h52.031v52.031\n' +
            'c0,11.828,9.594,21.422,21.422,21.422s21.438-9.594,21.438-21.422v-52.031h52.016c11.844,0,21.438-9.594,21.438-21.422\n' +
            'C333.277,226.551,323.684,216.957,311.84,216.957z\n' +
            'path://M495.152,413.801l-52.688-52.688c22.688-37.688,34.344-80.266,34.313-122.734\n' +
            'c0.031-60.844-23.359-122.125-69.828-168.563C360.512,23.363,299.246-0.043,238.387,0.02\n' +
            'C177.527-0.059,116.262,23.363,69.824,69.816C23.355,116.254-0.035,177.535-0.004,238.379\n' +
            'c-0.063,60.859,23.359,122.141,69.828,168.578c46.422,46.453,107.703,69.859,168.563,69.813\n' +
            'c42.469,0.047,85.031-11.625,122.734-34.297l52.672,52.656c22.469,22.469,58.891,22.469,81.359,0\n' +
            'C517.621,472.676,517.621,436.254,495.152,413.801z M341.871,341.863c-28.703,28.688-65.875,42.828-103.484,42.875\n' +
            'c-37.609-0.047-74.766-14.188-103.484-42.875C106.23,313.16,92.09,275.988,92.043,238.379\n' +
            'c0.047-37.609,14.188-74.781,42.859-103.484c28.703-28.656,65.875-42.797,103.484-42.859\n' +
            'c37.609,0.063,74.781,14.203,103.484,42.859c28.672,28.703,42.797,65.875,42.875,103.484\n' +
            'C384.668,275.988,370.543,313.16,341.871,341.863z',
          onclick: function () {
            props.zoomCharts(props.zoomChartId);
          }
        },
        saveAsImage: {
          show: props.exportFields ? true : false,
          backgroundColor: myTheme.palette.background.default
        },
        myCSV: {
          show: props.exportFields ? true : false,
          title: t('CSV出力'),
          icon:
            'path://M5.029,1.734h10.935c0.317,0,0.575-0.257,0.575-0.575s-0.258-0.576-0.575-0.576H5.029\n' +
            'c-0.318,0-0.576,0.258-0.576,0.576S4.711,1.734,5.029,1.734z M1,5.188V19h18.417V5.188H1z M18.266,17.849H2.151V6.338h16.115\n' +
            'V17.849z M2.727,4.036H17.69c0.317,0,0.575-0.257,0.575-0.576c0-0.318-0.258-0.575-0.575-0.575H2.727\n' +
            'c-0.318,0-0.576,0.257-0.576,0.575C2.151,3.779,2.409,4.036,2.727,4.036z',
          onclick: function () {
            clickButtonFromChart(
              {
                data: props.exportData,
                fields: props.exportFields,
                filename: props.exportFilename
              },
              'csv'
            );
          }
        },
        myExcel: {
          show: props.exportFields ? true : false,
          title: t('Excel出力'),
          icon:
            'path://M15.123,9.991c-0.944,0-1.71,0.766-1.71,1.71c0,0.945,0.766,1.711,1.71,1.711c0.945,0,1.711-0.766,1.711-1.711\n' +
            'C16.834,10.756,16.068,9.991,15.123,9.991z M15.703,12.281h-1.141v-1.141h1.141V12.281z M17.984,4.867h-2.288v-0.57h-0.024\n' +
            'c0.011-0.086,0.025-0.17,0.025-0.26V2.852c0-1.092-0.838-1.977-1.871-1.977H2.745C1.8,0.875,1.027,1.618,0.9,2.58H0.875v15.404\n' +
            'c0,0.629,0.511,1.141,1.141,1.141h15.969c0.629,0,1.14-0.512,1.14-1.141V6.008C19.124,5.377,18.613,4.867,17.984,4.867z\n' +
            'M2.016,2.586c0-0.315,0.255-0.57,0.57-0.57h11.406c0.314,0,0.57,0.255,0.57,0.57v2.275H2.016V2.586z M17.984,17.414\n' +
            'c0,0.314-0.257,0.57-0.57,0.57H2.586c-0.315,0-0.57-0.256-0.57-0.57V6.578c0-0.315,0.255-0.57,0.57-0.57h14.828\n' +
            'c0.313,0,0.57,0.255,0.57,0.57V17.414z',
          onclick: function () {
            clickButtonFromChart(
              {
                data: props.exportData,
                fields: props.exportFields,
                filename: props.exportFilename
              },
              'excel'
            );
          }
        }
      },
      tooltip: {
        show: true,
        formatter: '{b}'
      }
    },
    ...props.option,
    graphic: props.graphic,
    animation: true,
    animationDuration: 1000
  };

  // eChartsを参照するレフ。
  const eChartsRef = useRef(null as any);
  let eCharts_instance: any;

  if (eChartsRef && eChartsRef.current) {
    // eChartsのレフからインスタンスを取得
    eCharts_instance = eChartsRef.current?.getEchartsInstance();
    if (fixAnimeChart) {
      eCharts_instance.on('rendered', () => {
        options = {
          ...eCharts_instance.getOption(),
          animation: false,
          animationDuration: 0
        };
      });
    }
    eCharts_instance.setOption(options);
  }

  if (!props.zoomStatus) {
    return (
      <>
        <ReactEcharts
          ref={eChartsRef}
          theme={mySettings.isDark ? 'dark' : 'light'}
          opts={{locale: i18n.language.toUpperCase()}}
          notMerge={true}
          lazyUpdate={false}
          style={{...(props.height ? {height: props.height} : {height: '300px'})}}
          option={options}
          onEvents={props.onEvents}
        />
      </>
    );
  } else {
    return (
      <Dialog open={props.zoomStatus} onClose={props.zoomChartsClose} maxWidth={false} fullWidth>
        <Stack className={classes.root}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={props.zoomChartsClose}
            style={{marginRight: '0'}}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent id={'graphZoom'}>
          <ReactEcharts
            ref={eChartsRef}
            theme={mySettings.isDark ? 'dark' : 'light'}
            opts={{locale: i18n.language.toUpperCase()}}
            notMerge={true}
            lazyUpdate={false}
            style={{height: '85vh'}}
            option={{
              ...options,
              grid: {
                top: '10%',
                bottom: '15%',
                right: '8%',
                left: '8%'
              },
              dataZoom: [
                {
                  type: 'slider',
                  id: 'x',
                  show: true,
                  xAxisIndex: [0, 1],
                  // yAxisIndex: [0],
                  start: 0,
                  end: 100,
                  height: 20,
                  top: '6%',
                  filterMode: 'empty'
                },
                {
                  type: 'slider',
                  id: 'y',
                  show: true,
                  yAxisIndex: 0,
                  start: 0,
                  end: 100,
                  width: 20,
                  left: '92%',
                  filterMode: 'empty'
                }
              ]
            }}
            onEvents={props.onEvents}
          />
        </DialogContent>
      </Dialog>
    );
  }
};
export default LFCBaseCharts;
