import {ChangeEvent} from 'react';
import {
  Paper,
  Checkbox,
  Tooltip,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box
} from '@mui/material';
import {TFunction} from 'i18next';

interface CustomSelectProps {
  mapSeries: string[];
  selectedValues: string[];
  setSelectedValues: (values: string[]) => void;
  startProcess: boolean;
  t: TFunction<'translation', undefined, 'translation'>;
}

export const CustomSelect = ({
  mapSeries,
  selectedValues,
  setSelectedValues,
  startProcess,
  t
}: CustomSelectProps) => {
  const handleChange = (event: any, item: string) => {
    if (startProcess) return;
    event.stopPropagation();
    const newSelectedValues = selectedValues.includes(item)
      ? selectedValues.filter(value => value !== item)
      : [...selectedValues, item];
    setSelectedValues(newSelectedValues);
  };
  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (startProcess) return;
    if (event.target.checked) {
      setSelectedValues(mapSeries);
    } else {
      setSelectedValues([]);
    }
  };

  return (
    <div style={{marginRight: '20px', height: 'auto', position: 'fixed'}}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="200px"
      >
        <Typography variant="h6">{t('部位名選択')}</Typography>
        <Tooltip title={t('全部位選択')} placement="top" sx={{position: 'absolute', right: -16}}>
          <FormControlLabel
            control={
              <Checkbox
                checked={mapSeries.length > 0 && selectedValues.length === mapSeries.length}
                onChange={handleSelectAll}
                color="primary"
              />
            }
            label=""
          />
        </Tooltip>
      </Box>

      <Paper sx={{height: '60vh', overflow: 'auto'}}>
        <List>
          {mapSeries.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                backgroundColor: selectedValues.includes(item) ? 'gray' : '',
                cursor: 'pointer'
              }}
              onClick={event => handleChange(event, item)}
              disabled={startProcess}
            >
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </div>
  );
};
