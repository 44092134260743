import {getLFCData} from '_logics/LFCUtil';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {createContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useMySettings} from './MySettingsProvider';

export const MenuTabContext = createContext({
  menuData: [],
  tabData: [],
  reLoad: () => console.error('false')
});

export const MenuTabProvider = (props: any) => {
  const {children} = props;
  const {enqueueSnackbar} = useSnackbar();
  const mySettings = useMySettings();
  const [menuData, setMenuData] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [menuDataOutput, setMenuDataOutput] = useState([]);
  const [tabDataOutput, setTabDataOutput] = useState([]);
  const [menuTabContext, setMenuTabContext] = useState({
    menuData: menuData,
    tabData: tabData,
    reLoad: () => console.error('false')
  });
  const {t} = useTranslation();

  // CancelTokenをページに１つ
  const [source] = useState(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  /**
   * 認証情報変更時の処理
   */
  useEffect(() => {
    if (0 === Object.keys(mySettings).length) {
      //未認証だったら初期化
      setMenuData([]);
      setTabData([]);
      return;
    }
    loadApiFetch();
  }, [mySettings]);

  const loadApiFetch = async () => {
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 90001,
      parameters: {},
      ds_state: setMenuData,
      name: 'メニュー取得',
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 90002,
      parameters: {},
      ds_state: setTabData,
      name: 'メニュータブ取得',
      cancelToken: source.token,
      t
    });
  };

  useEffect(() => {
    const myMenu_exist: any = menuData.find((datas: any) => datas.tab_name === 'マイメニュー');
    if (myMenu_exist === undefined) {
      tabData.splice(0, 1);
    }
    setMenuDataOutput(menuData);
    setTabDataOutput(tabData);
  }, [menuData, tabData]);

  useEffect(() => {
    setMenuTabContext({menuData: menuDataOutput, tabData: tabDataOutput, reLoad: loadApiFetch});
  }, [menuDataOutput, tabDataOutput]);

  return (
    <div>
      <MenuTabContext.Provider value={menuTabContext}>{children}</MenuTabContext.Provider>
    </div>
  );
};

export default MenuTabProvider;
