import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty, max} from 'lodash';
import LFCChartsInputScaleDialog, {
  initialScaleValue,
  ScaleValue
} from './components/LFCChartsInputScale';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import {fmtYYYYMMDDHHmmss} from '_logics/LFCFormatUtil';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  xAxisName?: string;
  yAxisName?: string;
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  top?: string;
  onClick?: (prm: {data: any}) => void;
  mapName?: string;
  refJobTitle?: string;
  parameterName?: string;
  inputScale?: boolean;
  xlist: any;
  isShowDetailAnalysis?: boolean;
  onClickDetailAnalysis?: any;
  zoomStatus?: boolean;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  searchValue?: any;
  averageUpperDistance?: any;
  medianUpperDistance?: any;
  averagePlus3SD?: any;
  averageMinus3SD?: any;
  searchType?: number;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBoxXYZ8 = (props: Props) => {
  const {t} = useTranslation();
  const [scaleValue, setScaleValue] = useState<ScaleValue>(initialScaleValue);
  const [inputScaleOpen, setInputScaleOpen] = useState(false);
  const [boxData, setBoxData] = useState([]);
  // スケール変更
  const onApply = (scaleValue: ScaleValue) => {
    setScaleValue(scaleValue);
  };

  const makeBoxData = () => {
    //ボックスデータの算出
    const CalcQuartile = (data: any) => {
      let max = Math.max(...data.map((a: any) => Number(a)));
      let min = Math.min(...data.map((a: any) => Number(a)));

      const dataQ1 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.25;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ2 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.5;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ3 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.75;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };
      // [min,  Q1,  median (or Q2),  Q3,  max]
      return [min, dataQ1(), dataQ2(), dataQ3(), max];
    };

    let tmp_upper_distance: any = [];
    let tmp_lower_distance: any = [];
    let tmpOutPut: any = [];
    tmpOutPut[0] = [];
    tmpOutPut[1] = [];
    tmpOutPut[2] = [];

    if (!isEmpty(props.xlist)) {
      props.xlist.forEach((a: string) => {
        if (props.source.xlist.find((k: string) => k === a)) {
          props.source.xlist.find((b: string, index: number) => {
            if (b === a) {
              tmp_upper_distance.push(props.source.data_upper_distance[index]);
              tmp_lower_distance.push(props.source.data_lower_distance[index]);
            }
          });
        } else {
          tmp_upper_distance.push([]);
          tmp_lower_distance.push([]);
        }
      });

      tmp_upper_distance.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmpOutPut[0].push(CalcQuartile(a));
        } else {
          tmpOutPut[0].push([]);
        }
      });
      tmp_lower_distance.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmpOutPut[1].push(CalcQuartile(a));
        } else {
          tmpOutPut[1].push([]);
        }
      });
    } else {
      props.source.xlist.find((b: string, index: number) => {
        tmpOutPut[0].push(CalcQuartile([props.source.data_upper_distance[index]]));
        tmpOutPut[1].push(CalcQuartile([props.source.data_lower_distance[index]]));
      });
    }
    setBoxData(tmpOutPut);
  };

  const [exportData, setExportData] = useState([]);

  useEffect(() => {
    makeBoxData();
  }, [props]);

  useEffect(() => {
    if (boxData.length === 0) return;
    const _exportData = props?.source?.xlist.map((date: any, i: number) => {
      return {
        機種: props.searchValue.work.join(','),
        検査装置: props.searchValue.deviceid.join(','),
        検査部位: props.mapName,
        検査日時: fmtYYYYMMDDHHmmss(props.source.lq_time),
        距離: props.source.data_lower_distance[i][0],
        シリアルナンバー: props.source.uuid
      };
    });
    setExportData(_exportData);
  }, [boxData]);

  const calcDataAvgMel = (source: any) => {
    return source.data_lower_distance.map((sublist: any[]) => {
      if (!Array.isArray(sublist) || sublist.length === 0) {
        return null;
      }
      const sum = sublist.reduce((acc, val) => acc + val, 0);
      return sum / sublist.length;
    });
  };
  const calcDataMiddleMel = (source: any) => {
    return source.data_lower_distance.map((sublist: any[]) => {
      if (!Array.isArray(sublist) || sublist.length === 0) {
        return null;
      }
      const sortedList = sublist.slice().sort((a, b) => a - b);
      const midIndex = Math.floor(sortedList.length / 2);

      if (sortedList.length % 2 === 0) {
        return (sortedList[midIndex - 1] + sortedList[midIndex]) / 2;
      } else {
        return sortedList[midIndex];
      }
    });
  };
  let tooltipContent = '';

  const legendChange = (data: any) => {
    const selectedData = data.selected;
    tooltipContent = '';

    if (selectedData['平均-3σ']) {
      tooltipContent += '<br/>' + t('平均-3σ') + ':' + Number(props.averageMinus3SD).toFixed(2);
    }

    if (selectedData['平均+3σ']) {
      tooltipContent += '<br/>' + t('平均+3σ') + ':' + Number(props.averagePlus3SD).toFixed(2);
    }

    if (selectedData['平均']) {
      tooltipContent += '<br/>' + t('平均') + ':' + Number(props.averageUpperDistance).toFixed(2);
    }

    if (selectedData['中央']) {
      tooltipContent += '<br/>' + t('中央') + ':' + Number(props.medianUpperDistance).toFixed(2);
    }
  };

  return (
    <div>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          legend: {
            id: 1,
            type: 'scroll',
            itemWidth: 10,
            show: true,
            top: '90%',
            textStyle: {
              fontSize: 10
            },
            data: [
              t('箱ひげ図'),
              t('群平均'),
              t('群中央'),
              t('最小値'),
              t('上限'),
              t('下限'),
              t('中央'),
              t('平均'),
              t('平均+3σ'),
              t('平均-3σ')
            ],
            selected: {
              上限: true,
              下限: true,
              中央: false,
              平均: false,
              '平均+3σ': false,
              '平均-3σ': false,
              群平均: false,
              群中央: false
            }
          },
          tooltip: {
            trigger: 'axis', // 軸に沿ってtooltipを表示するために'axis'を使用
            formatter: function (params: any) {
              if (props.searchType === 0) {
                return (
                  t('連番') +
                  ':' +
                  params[0].name +
                  '<br/>' +
                  t('シリアルナンバー') +
                  ':' +
                  props.source.uuid +
                  '<br/>' +
                  t('検査日時') +
                  ':' +
                  dayjs(props.source.lq_time).format('YYYY-MM-DD HH:mm:ss') +
                  '<br/>' +
                  t('最小値') +
                  ':' +
                  (isNaN(params[0].value) ? 'データなし' : Number(params[0].value).toFixed(2)) +
                  '<br/>' +
                  t('上限') +
                  ':' +
                  (isNaN(params[1].value) ? 'データなし' : Number(params[1].value).toFixed(2)) +
                  '<br/>' +
                  t('下限') +
                  ':' +
                  (isNaN(params[2].value) ? 'データなし' : Number(params[2].value).toFixed(2))
                );
              }
              return (
                params[0].name +
                ':' +
                t('最大値') +
                '<br/>' +
                t('最大') +
                ':' +
                (isNaN(params[0].value[5]) ? 'データなし' : Number(params[0].value[5]).toFixed(2)) +
                '<br/>' +
                'Q3：' +
                (isNaN(params[0].value[4]) ? 'データなし' : Number(params[0].value[4]).toFixed(2)) +
                '<br/>' +
                t('中央') +
                ' (or Q2)：' +
                (isNaN(params[0].value[3]) ? 'データなし' : Number(params[0].value[3]).toFixed(2)) +
                '<br/>' +
                'Q1：' +
                (isNaN(params[0].value[2]) ? 'データなし' : Number(params[0].value[2]).toFixed(2)) +
                '<br/>' +
                t('最小') +
                ':' +
                (isNaN(params[0].value[1]) ? 'データなし' : Number(params[0].value[1]).toFixed(2)) +
                '<br/>' +
                '----' +
                '<br/>' +
                t('上限') +
                ':' +
                (isNaN(params[1].value) ? 'データなし' : Number(params[1].value).toFixed(2)) +
                '<br/>' +
                t('下限') +
                ':' +
                (isNaN(params[2].value) ? 'データなし' : Number(params[2].value).toFixed(2)) +
                tooltipContent
              );
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '10%',
            right: '8%',
            bottom: props.bottom,
            top: props.top
          },
          xAxis: {
            type: 'category',
            name: props.xAxisName !== undefined ? props.xAxisName : '',
            data: !isEmpty(props.xlist) ? props.xlist : props.source.xlist,
            nameTextStyle: {
              fontSize: 10
            },
            nameLocation: 'end',
            splitLine: {
              show: true
            },
            axisLabel: {
              rotate: '15',
              fontSize: 10
            }
          },
          yAxis: {
            type: 'value',
            name: props.yAxisName !== undefined ? props.yAxisName : '',
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              interval: 0,
              fontSize: 10
            },
            interval: scaleValue.y1AxisInterval
          },
          series:
            props.searchValue.searchType === 0
              ? [
                  {
                    name: t('最小値'),
                    type: 'line',
                    data: props.source.data_lower_distance,
                    color: 'rgb(243,6,6)'
                  },
                  {
                    name: t('上限'),
                    type: 'line',
                    color: '#FF1493',
                    data: props.source.data_upper_limits.map((e: any) => {
                      return e ?? null;
                    }),
                    symbolSize: 1,
                    connectNulls: true // null を無視して線をつなぐ
                  },
                  {
                    name: t('下限'),
                    type: 'line',
                    color: '#FF4500',
                    data: props.source.data_lower_limits.map((e: any) => {
                      return e ?? null;
                    }),
                    symbolSize: 1,
                    connectNulls: true // null を無視して線をつなぐ
                  }
                ]
              : [
                  {
                    name: t('箱ひげ図'),
                    type: 'boxplot',
                    data: boxData[1],
                    color: 'rgb(243,6,6)'
                  },
                  {
                    name: t('上限'),
                    type: 'line',
                    color: '#FF1493',
                    symbolSize: 1,
                    data: props.source.data_upper_limits.map((e: any) => {
                      return e?.[0] ?? null;
                    }),
                    connectNulls: true // null を無視して線をつなぐ
                  },
                  {
                    name: t('下限'),
                    type: 'line',
                    color: '#FF4500',
                    symbolSize: 1,
                    data: props.source.data_lower_limits.map((e: any) => {
                      return e?.[0] ?? null;
                    }),
                    connectNulls: true // null を無視して線をつなぐ
                  },
                  {
                    name: t('中央'),
                    type: 'line',
                    color: colorPallet[2],
                    markLine: {
                      data: [
                        {
                          type: 'guide',
                          yAxis: props.medianUpperDistance,
                          name: t('中央')
                        }
                      ],
                      lineStyle: {
                        color: colorPallet[2],
                        type: 'solid',
                        width: 2
                      },
                      symbol: ['none', 'none'],
                      label: {
                        formatter: t('中央'),
                        show: true,
                        position: 'end'
                      }
                    }
                  },
                  {
                    name: t('平均'),
                    type: 'line',
                    color: colorPallet[4],
                    markLine: {
                      data: [
                        {
                          type: 'guide',
                          yAxis: props.averageUpperDistance,
                          name: t('平均')
                        }
                      ],
                      lineStyle: {
                        color: colorPallet[4],
                        type: 'solid',
                        width: 2
                      },
                      symbol: ['none', 'none'],
                      label: {
                        formatter: t('平均'),
                        show: true,
                        position: 'end'
                      }
                    }
                  },
                  {
                    name: t('平均+3σ'),
                    type: 'line',
                    color: colorPallet[5],
                    markLine: {
                      data: [
                        {
                          type: 'guide',
                          yAxis: props.averagePlus3SD,
                          name: t('平均+3σ')
                        }
                      ],
                      lineStyle: {
                        color: colorPallet[5],
                        type: 'solid',
                        width: 2
                      },
                      symbol: ['none', 'none'],
                      label: {
                        formatter: t('平均+3σ'),
                        show: true,
                        position: 'end'
                      }
                    }
                  },
                  {
                    name: t('平均-3σ'),
                    type: 'line',
                    color: colorPallet[6],
                    markLine: {
                      data: [
                        {
                          type: 'guide',
                          yAxis: props.averageMinus3SD,
                          name: t('平均-3σ')
                        }
                      ],
                      lineStyle: {
                        color: colorPallet[6],
                        type: 'solid',
                        width: 2
                      },
                      symbol: ['none', 'none'],
                      label: {
                        formatter: t('平均-3σ'),
                        show: true,
                        position: 'end'
                      }
                    }
                  },
                  {
                    name: t('群平均'),
                    type: 'line',
                    legendHoverLink: true,
                    showSymbol: true,
                    showAllSymbol: true,
                    data: props.source ? calcDataAvgMel(props.source) : false,
                    lineStyle: {
                      width: 1
                    },
                    color: colorPallet[1],
                    connectNulls: true // データがない部分を飛ばして線を繋ぐ
                  },
                  {
                    name: t('群中央'),
                    type: 'line',
                    legendHoverLink: true,
                    showSymbol: true,
                    showAllSymbol: true,
                    data: props.source ? calcDataMiddleMel(props.source) : [],
                    lineStyle: {
                      width: 1
                    },
                    color: colorPallet[3],
                    connectNulls: true // データがない部分を飛ばして線を繋ぐ
                  }
                ]
        }}
        exportData={exportData}
        exportFields={{
          機種: '機種',
          検査装置: '検査装置',
          検査部位: '検査部位',
          検査日時: '検査日時',
          距離: '距離',
          シリアルナンバー: 'シリアルナンバー'
        }}
        exportFilename={`CloudDistance_${t('最小値推移')}`}
        height={props.height ? props.height : '100px'}
        onEvents={{
          click: (params: any) => {
            if (props.onClick != null) {
              params.mapName = props.mapName;
              params.refJobTitle = props.refJobTitle;
              params.parameterName = props.parameterName;
              props.onClick(params);
            }
          },
          legendselectchanged: (params: any) => {
            legendChange(params);
          }
        }}
        inputScaleStatus={props.inputScale !== undefined ? props.inputScale : false}
        inputScaleOpen={() => setInputScaleOpen(true)}
        isShowDetailAnalysis={props.isShowDetailAnalysis ?? false}
        onClickDetailAnalysis={props.onClickDetailAnalysis ?? undefined}
        zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
        zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
        zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
        zoomStatus={props.zoomChartId === props.zoomedChartId}
      />
      <LFCChartsInputScaleDialog
        open={inputScaleOpen}
        onClose={() => setInputScaleOpen(false)}
        onApply={onApply}
        y1AxisName={props.yAxisName}
        inputY1Axis={true}
      />
    </div>
  );
};
export default LFCChartsBoxXYZ8;
