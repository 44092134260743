import {Box, FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import {handleInputChange2} from '_logics/LFCUtil';
import {TFunction} from 'i18next';
import preIcon from '_assets/images/lq-job/inquiry-icon.svg';
import {useEffect} from 'react';

interface CloudDistanceDigSearchPanelProps {
  t: TFunction<'translation', undefined, 'translation'>;
  classes: Record<'search' | 'root' | 'tableLine' | 'tableLineDark', string>;
  searchValue: {
    select_date_from: string;
    select_date_to: string;
    work: string[];
    deviceid: string[];
    judge: number;
    map: string[];
    searchType: number;
  };
  setSearchValue: (
    value: React.SetStateAction<{
      select_date_from: string;
      select_date_to: string;
      work: string[];
      deviceid: string[];
      judge: number;
      map: string[];
      searchType: number;
    }>
  ) => void;
  handleChange: (event: any) => void;
  startProcess: boolean;
  work: {label: string}[];
  map: {label: string}[];
  deviceid: {label: string}[];
  autoOnChange: (relayDatas: any) => void;
  autoCompleteReset: boolean;
  setAutoCompleteReset: React.Dispatch<React.SetStateAction<boolean>>;
  doSearch: any;
  restSearch: any;
  setDetailListOpen: any;
  searchType: any;
  onChangeRadio: any;
}

export const CloudDistanceDigSearchPanel = ({
  t,
  classes,
  searchValue,
  setSearchValue,
  handleChange,
  startProcess,
  work,
  map,
  deviceid,
  autoOnChange,
  autoCompleteReset,
  setAutoCompleteReset,
  doSearch,
  restSearch,
  setDetailListOpen,
  searchType,
  onChangeRadio
}: CloudDistanceDigSearchPanelProps) => {
  console.log('DEBUG1', searchValue);
  console.log('DEBUG2', map);
  console.log('DEBUG3', multiSelectData(map, searchValue.map));
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work, deviceid, map]);
  return (
    <Box>
      <form className={classes.search}>
        <LFCFormRowGroup>
          <LFCDatetimePicker
            name={t('select_date_from')}
            label={t('対象期間From')}
            value={searchValue.select_date_from}
            required
            onChange={handleChange}
            disabled={startProcess}
          />
          <LFCDatetimePicker
            name={t('select_date_to')}
            label={t('対象期間To')}
            value={searchValue.select_date_to}
            required
            onChange={handleChange}
            disabled={startProcess}
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small"
            value={multiSelectData(work, searchValue.work)}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
            disabled={startProcess}
          />
          <LFCAutocomplete
            name={t('map')}
            label={t('検査部位')}
            id={'map'}
            size="small"
            value={multiSelectData(map, searchValue.map)}
            onChange={autoOnChange}
            multiple={true}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            selectItem={map}
            disabled={startProcess}
          />
          <LFCAutocomplete
            name={'deviceid'}
            label={t('検査装置')}
            id={'deviceid'}
            size="small"
            value={multiSelectData(deviceid, searchValue.deviceid)}
            onChange={autoOnChange}
            multiple={true}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            selectItem={deviceid}
            disabled={startProcess}
          />
          <LFCSelectFormJudge
            name={'judge'}
            value={searchValue.judge}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
            disabled={startProcess}
          />

          <LFCButton color="primary" onClick={doSearch} disabled={startProcess}>
            {t('検索')}
          </LFCButton>
          <LFCButton onClick={restSearch} disabled={startProcess}>
            {t('リセット')}
          </LFCButton>
        </LFCFormRowGroup>
      </form>
      <div style={{float: 'left', margin: 10, paddingRight: 10}}>{t('集計単位')}:</div>
      <div style={{float: 'right'}}>
        <LFCButton
          size={'small'}
          color="secondary"
          onClick={() => setDetailListOpen(true)}
          style={{padding: '5px 25px 5px 10px'}}
        >
          <img src={preIcon} alt="icon" width={20} height={20} style={{marginRight: 20}} />
          {t('データ一覧')}
        </LFCButton>
      </div>
      <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
        {searchType.map((item: any) => (
          <FormControlLabel
            disabled={startProcess}
            value={item.id}
            key={'search_type-' + item.id}
            control={
              <Radio onChange={onChangeRadio} checked={item.id === searchValue.searchType} />
            }
            label={
              <Typography display="block" key={'search_type-body-' + item.id}>
                {item.text}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </Box>
  );
};
