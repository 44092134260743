import {makeStyles} from 'tss-react/mui';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useState} from 'react';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import dayjs from 'dayjs';
import axios from 'axios';
import {getLFCData} from '_logics/LFCUtil';
import {isEmpty} from 'lodash';
import {LOCATIONID_LQ_JOBANALYSIS_HOLEPOSITION_NEW, PAGEID_LQ_JOBANALYSIS} from '_logics/LFCPageId';
import {CloudDistanceListData, CloudDistanceSearchValue} from '../types/indes';

export const useCloudDistance = () => {
  const useStyles = makeStyles()(theme => {
    return {
      search: {
        display: 'flex',
        alignItems: 'start',
        '& > *': {
          margin: theme.spacing(1)
        }
      },
      fixedDarkHeader: {
        position: 'fixed',
        top: 60,
        left: 0,
        right: 0,
        zIndex: 1000,
        padding: theme.spacing(2),
        backgroundColor: 'black',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
      },
      fixedLightHeader: {
        position: 'fixed',
        top: 60,
        left: 0,
        right: 0,
        zIndex: 1000,
        padding: theme.spacing(2),
        backgroundColor: 'white',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
      },
      content: {
        marginTop: '200px',
        [theme.breakpoints.down('sm')]: {
          marginTop: '250px'
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: '250px'
        }
      }
    };
  });
  const {classes} = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState<boolean>(false);
  const {t} = useTranslation();
  const getDefaultCondition = useGetDefaultCondition();

  const [searchValue, setSearchValue] = useState<CloudDistanceSearchValue>(
    getDefaultCondition(PAGEID_LQ_JOBANALYSIS, LOCATIONID_LQ_JOBANALYSIS_HOLEPOSITION_NEW) ?? {
      select_date_from: dayjs().add(-7, 'd').format('YYYY-MM-DD HH:mm:ss'),
      select_date_to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      work: [],
      deviceid: [],
      judge: 9,
      searchType: 2
    }
  );

  const [listData, setListData] = useState<CloudDistanceListData[]>([]);
  const [mapData, setMapData] = useState<string[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [graphBaseData, setGraphBaseData] = useState<
    {
      data_lower_distance: number[][];
      data_upper_distance: number[][];
      map: string;
      xlist: string[];
    }[]
  >([]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const dateCalc = useCallback(() => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_date_from);
    let to_day = new Date(searchValue.select_date_to);

    switch (searchValue.searchType) {
      case 0:
        return [];
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        let start_month_1day = new Date(from_day.setDate(1));
        let end_month_1day = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
    }
  }, [startProcess]);

  const handleSearch = async () => {
    setStartProcess(true);
    setSelectedValues([]);
    setRequestedMapValues([]);
    setListData([]);
    setGraphBaseData([]);
    setMapData([]);
    /* Map一覧取得 */
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 30004,
      parameters: {
        select_date_from: searchValue.select_date_from,
        select_date_to: searchValue.select_date_to,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
        deviceid: !isEmpty(searchValue.deviceid)
          ? '{' + searchValue.deviceid.join(',') + '}'
          : null,
        judge: searchValue.judge === 9 ? null : searchValue.judge
      },
      name: 'id-30004',
      cancelToken: source.token,
      t
    }).then(async data => {
      const filteredDatas = data
        .filter((item: {map: string}[]) => item.map)
        .map((item: {map: string}[]) => item.map);
      setMapData(filteredDatas);
      if (filteredDatas.length > 0) {
        const response = await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 30003,
          parameters: {
            select_date_from: searchValue.select_date_from,
            select_date_to: searchValue.select_date_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
            deviceid: !isEmpty(searchValue.deviceid)
              ? '{' + searchValue.deviceid.join(',') + '}'
              : null,
            judge: searchValue.judge === 9 ? null : searchValue.judge,
            map: filteredDatas[0]
          },
          name: 'id-30003',
          cancelToken: source.token,
          t
        });
        setRequestedMapValues([...requestedMapValues, filteredDatas[0]]);
        setListData(response);
        setSelectedValues([filteredDatas[0]]);
      }
    });
    setStartProcess(false);
  };

  const resetSearch = () => {
    setStartProcess(false);
    setSearchValue({
      select_date_from: dayjs().add(-7, 'd').format('YYYY-MM-DD HH:mm:ss'),
      select_date_to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      work: [],
      deviceid: [],
      judge: 9,
      searchType: 2
    });
    setMapData([]);
    setGraphBaseData([]);
    setSelectedValues([]);
    setRequestedMapValues([]);
  };

  const graphClick = (datas: any) => {
    setOpenData(datas);
    setOpen(true);
  };

  const [open, setOpen] = useState(false);
  const [openData, setOpenData] = useState<any>([]);
  const handleClose = () => {
    setOpenData([]);
    setOpen(false);
  };

  const [requestedMapValues, setRequestedMapValues] = useState<string[]>([]);
  useEffect(() => {
    (async () => {
      /* 選択したmapがすでにlistDataに含まれる場合、リクエストからは除外する */
      const data = selectedValues.filter(e => !requestedMapValues.includes(e));
      /* dataに対して一件ずつリクエストしてlistDataに格納していく */
      for (const item of data) {
        /* Map一覧取得 */
        try {
          const response = await getLFCData({
            snack: enqueueSnackbar,
            sql_id: 30003,
            parameters: {
              select_date_from: searchValue.select_date_from,
              select_date_to: searchValue.select_date_to,
              work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
              deviceid: !isEmpty(searchValue.deviceid)
                ? '{' + searchValue.deviceid.join(',') + '}'
                : null,
              judge: searchValue.judge === 9 ? null : searchValue.judge,
              map: item
            },
            name: 'id-30003',
            cancelToken: source.token,
            t
          });
          const newListData = listData.concat(response);
          setRequestedMapValues([...requestedMapValues, item]);
          setListData(newListData);
        } catch (error) {
          enqueueSnackbar('リクエストに失敗しました: ' + item, {variant: 'error'});
        }
      }
    })();
  }, [selectedValues]);

  useEffect(() => {
    let tmp_all: any = [];
    selectedValues.forEach((map_string: string) => {
      let tmp_data_upper_distance: any = [];
      let tmp_data_lower_distance: any = [];
      if (searchValue.searchType !== 0) {
        dateCalc().forEach((lq_time_string: string) => {
          let tmp_upper_distance: any = [];
          let tmp_lower_distance: any = [];
          switch (searchValue.searchType) {
            case 0:
              listData.forEach((item: any) => {
                if (item.map === map_string) {
                  tmp_upper_distance.push(Number(item['upper_distance']));
                  tmp_lower_distance.push(Number(item['lower_distance']));
                }
              });
              break;
            case 1:
              listData.forEach((item: any) => {
                if (item.map === map_string && item.year_month_day_hour === lq_time_string) {
                  tmp_upper_distance.push(Number(item['upper_distance']));
                  tmp_lower_distance.push(Number(item['lower_distance']));
                }
              });
              break;
            case 2:
              listData.forEach((item: any) => {
                if (item.map === map_string && item.year_month_day === lq_time_string) {
                  tmp_upper_distance.push(Number(item['upper_distance']));
                  tmp_lower_distance.push(Number(item['lower_distance']));
                }
              });
              break;
            case 3:
              listData.forEach((item: any) => {
                if (item.map === map_string && item.year_month === lq_time_string) {
                  tmp_upper_distance.push(Number(item['upper_distance']));
                  tmp_lower_distance.push(Number(item['lower_distance']));
                }
              });
              break;
          }
          tmp_data_upper_distance.push(tmp_upper_distance);
          tmp_data_lower_distance.push(tmp_lower_distance);
        });
        tmp_all.push({
          map: map_string,
          data_upper_distance: tmp_data_upper_distance,
          data_lower_distance: tmp_data_lower_distance,
          xlist: dateCalc()
        });
      } else {
        tmp_all.push({
          map: map_string,
          data_upper_distance: listData
            .filter((a: any) => a.map === map_string)
            .map((b: any) => Number(b.upper_distance)),
          data_lower_distance: listData
            .filter((a: any) => a.map === map_string)
            .map((b: any) => Number(b.lower_distance)),
          xlist: listData.filter((a: any) => a.map === map_string).map((b: any) => b.idx)
        });
      }
    });
    setGraphBaseData(tmp_all);
  }, [selectedValues, listData]);
  const resetResults = () => {
    setMapData([]);
    setSelectedValues([]);
  };
  return {
    classes,
    startProcess,
    t,
    mapData,
    selectedValues,
    setSelectedValues,
    searchValue,
    dateCalc,
    handleSearch,
    resetSearch,
    graphClick,
    open,
    openData,
    handleClose,
    graphBaseData,
    listData,
    setSearchValue,
    resetResults,
    setStartProcess
  };
};
