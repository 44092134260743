import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import Decimal from 'decimal.js-light';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  xAxisName?: string;
  onClick?: (prm: {data: any}) => void;
  limit?: any;
  limitRow?: any;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  zoomStatus?: boolean;
  xLineMax?: number;
  xRenge?: number;
  averageUpperDistance?: any;
  medianUpperDistance?: any;
  averagePlus3SD?: any;
  averageMinus3SD?: any;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarWorkCloudDistance2 = (props: Props) => {
  const {t} = useTranslation();
  let rangeCounts: number[] = [];
  let y_value: number[] = [];
  let ranges: number[] = [];
  console.log({props});

  const [exportData, setExportData] = useState<any>([]);

  useEffect(() => {
    const _exportData = rangeCounts.map((value: any, i: number) => {
      return {
        距離: ranges
          .slice(0, -1)
          .map((value, index) => `${value.toFixed(2)}_${ranges[index + 1].toFixed(2)}`)[i],
        カウント数: value
      };
    });
    setExportData(_exportData);
  }, [props]);

  if (
    props.source.upper_limit !== undefined &&
    props.source.lower_limit !== undefined &&
    props.xRenge !== undefined &&
    !isNaN(props.xRenge) &&
    props.xRenge > 0 // x_range が正の値であることを確認
  ) {
    const x_start = props.source.upper_limit;

    const x_end = props.source.lower_limit;

    const x_range = props.xRenge;

    if (x_start < x_end) {
      console.error('Invalid range: upper_limit is less than lower_limit.');
      return <></>;
    }

    let current_value = x_start;
    let iterationCount = 0; // 安全対策のためのループ回数制限
    const maxIterations = 1000; // 適切な上限回数を設定

    while (current_value >= x_end) {
      if (iterationCount++ > maxIterations) {
        console.error('Infinite loop detected. Breaking the loop.');
        break;
      }
      const numberValue = Number(current_value);
      ranges.push(numberValue);
      current_value -= x_range;
    }
    const numberValue = Number(current_value);
    ranges.push(numberValue);

    ranges.sort((a, b) => a - b);
    console.log({ranges});

    if (ranges.length > 1) {
      rangeCounts = Array(ranges.length - 1).fill(0);
    } else {
      console.error('Ranges array does not have enough elements.');
    }

    props.source.data_lower_distance.flat().forEach((value: number) => {
      for (let i = 0; i < ranges.length - 1; i++) {
        if (value >= ranges[i] && value < ranges[i + 1]) {
          rangeCounts[i]++;
          break;
        }
      }
    });
  }

  const x_list = ranges
    .slice(0, -1)
    .map((value, index) => `${value.toFixed(2)}_${ranges[index + 1].toFixed(2)}`);

  const makeMin = () => {
    if (x_list[0] !== undefined) {
      let tmp: any = x_list[0].split('_');
      return Number(tmp[0]);
    } else {
      return null;
    }
  };

  const makeMax = () => {
    if (x_list[x_list.length - 1] !== undefined) {
      let tmp: any = x_list[x_list.length - 1].split('_');
      return Number(tmp[1]);
    } else {
      return null;
    }
  };
  console.log({makeMin: makeMin(), makeMax: makeMax()});

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          xAxis: [
            {
              type: 'category',
              data: x_list,
              axisLabel: {
                interval: 0,
                rotate: '15',
                fontSize: 10
              },
              xAxisIndex: 0
            },
            {
              type: 'value',
              axisLabel: {
                rotate: '15',
                fontSize: 10
              },
              min: makeMin(),
              max: makeMax(),
              xAxisIndex: 1,
              show: false,
              axisPointer: {
                show: true
              }
            }
          ],
          yAxis: {
            type: 'value',
            name: props.xAxisName,
            minInterval: 1,
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              interval: 0,
              fontSize: 10
            },
            yAxisIndex: 0,
            max: Math.max(...rangeCounts),
            offset: 0,
            axisLine: {
              onZero: false
            }
          },
          legend: {
            id: 1,
            type: 'scroll',
            itemWidth: 10,
            selected: {
              上限: true,
              下限: true,
              中央: false,
              平均: false,
              '平均-3σ': false,
              '平均+3σ': false
            },
            formatter: (prm: any) => {
              return t(prm);
            },
            data: [
              {
                name: t('上限'),
                icon: 'circle',
                itemStyle: {
                  color: colorPallet[7]
                }
              },
              {
                name: t('下限'),
                icon: 'circle',
                itemStyle: {
                  color: colorPallet[8]
                }
              },
              {
                name: t('中央'),
                icon: 'circle',
                itemStyle: {
                  color: colorPallet[2]
                }
              },
              {
                name: t('平均'),
                icon: 'circle',
                itemStyle: {
                  color: colorPallet[4]
                }
              },
              {
                name: t('平均-3σ'),
                icon: 'circle',
                itemStyle: {
                  color: colorPallet[6]
                }
              },
              {
                name: t('平均+3σ'),
                icon: 'circle',
                itemStyle: {
                  color: colorPallet[5]
                }
              }
            ],

            top: props.zoomStatus ? '95%' : '90%',
            textStyle: {
              fontSize: 10
            }
          },
          series: [
            {
              name: t('個'),
              data: rangeCounts,
              type: 'bar',
              barWidth: '100%',
              xAxisIndex: 0
            },
            {
              name: t('上限'),
              type: 'line',
              xAxisIndex: 1,
              markLine: {
                label: {
                  formatter: t('上限'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  color: colorPallet[7],
                  width: 2,
                  type: 'solid'
                },
                data:
                  props.source.upper_limit !== undefined
                    ? [
                        {
                          type: 'guide',
                          name: t('上限'),
                          xAxis: props.source.upper_limit
                        }
                      ]
                    : []
              }
            },

            {
              name: t('下限'),
              type: 'line',
              xAxisIndex: 1,
              markLine: {
                label: {
                  formatter: t('下限'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  color: colorPallet[8],
                  width: 2,
                  type: 'solid'
                },
                data:
                  props.source.lower_limit !== undefined
                    ? [
                        {
                          type: 'guide',
                          name: t('下限'),
                          xAxis: props.source.lower_limit
                        }
                      ]
                    : []
              }
            },
            {
              name: t('中央'),
              type: 'line',
              xAxisIndex: 1,
              markLine: {
                label: {
                  formatter: t('中央'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  color: colorPallet[2],
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('中央'),
                    xAxis: props.medianUpperDistance
                  }
                ]
              }
            },
            {
              name: t('平均'),
              type: 'line',
              xAxisIndex: 1,
              markLine: {
                label: {
                  formatter: t('平均'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  color: colorPallet[4],
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('平均'),
                    xAxis: props.averageUpperDistance
                  }
                ]
              }
            },
            {
              name: t('平均+3σ'),
              type: 'line',
              xAxisIndex: 1,
              markLine: {
                label: {
                  formatter: t('平均+3σ'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  color: colorPallet[5],
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('平均+3σ'),
                    xAxis: props.averagePlus3SD
                  }
                ]
              }
            },
            {
              name: t('平均-3σ'),
              type: 'line',
              xAxisIndex: 1,
              markLine: {
                label: {
                  formatter: t('平均-3σ'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  color: colorPallet[6],
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('平均-3σ'),
                    xAxis: props.averageMinus3SD
                  }
                ]
              }
            }
          ],
          tooltip: {
            trigger: 'item',
            formatter: (prm: any) => {
              if (prm.seriesName === t('個')) {
                return (
                  prm.name +
                  'mm<br>' +
                  t('検査数') +
                  ':' +
                  prm.value +
                  t('件') +
                  '<br>' +
                  t('構成比') +
                  ':' +
                  (
                    (prm.value / y_value.reduce((sum: number, item: any) => sum + item, 0)) *
                    100
                  ).toFixed(1) +
                  '%'
                );
              } else {
                return prm.data.name === t('上限')
                  ? t('上限') + ':' + props.source.upper_limit + 'mm'
                  : prm.data.name === t('下限')
                    ? t('下限') + ':' + props.source.lower_limit + 'mm'
                    : prm.data.name === t('中央')
                      ? t('中央') + ':' + props.medianUpperDistance + 'mm'
                      : prm.data.name === t('平均')
                        ? t('平均') + ':' + props.averageUpperDistance + 'mm'
                        : prm.data.name === t('平均-3σ')
                          ? t('平均-3σ') + ':' + props.averageMinus3SD + 'mm'
                          : prm.data.name === t('平均+3σ')
                            ? t('平均+3σ') + ':' + props.averagePlus3SD + 'mm'
                            : '';
              }
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '10%',
            right: '5%',
            bottom: '30%'
          }
        }}
        exportData={exportData}
        exportFields={{
          距離: t('距離'),
          カウント数: t('カウント数')
        }}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
        onEvents={{
          click: (params: any) => {
            if (params.seriesName === t('個') && props.onClick != null) {
              props.onClick(params);
            }
          }
        }}
        zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
        zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
        zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
        zoomStatus={props.zoomChartId === props.zoomedChartId}
      />
    </div>
  );
};
export default LFCChartsBarWorkCloudDistance2;
