import {Box, Dialog, DialogContent} from '@mui/material';
import ProgressBar from '_components/ProgressBar';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import {CloudDistanceDigSearchPanel} from './components/CloudDistanceDigSearchPanel';
import {CloudDistanceMaxMinComparison} from './components/CloudDistanceMaxMinComparison';
import {CloudDistanceMaxAnalysis} from './components/CloudDistanceMaxAnalysis';
import {useCloudDistanceDig} from './hooks/useCloudDistanceDig';
import {CloudDistanceMinAnalysis} from './components/CloudDistanceMinAnalysis';
import {useEffect} from 'react';

/**
 * 引数
 */
interface Props {
  pageName: string;
  open: boolean;
  onClose: () => void;
  datas: any;
  searchValue: any;
  mapData: string[];
}

const CloudDistanceDig = (props: Props) => {
  const {
    startProcess,
    t,
    classes,
    searchValue,
    setSearchValue,
    handleChange,
    work,
    deviceid,
    autoOnChange,
    autoCompleteReset,
    setAutoCompleteReset,
    doSearch,
    restSearch,
    setDetailListOpen,
    searchType,
    onChangeRadio,
    listData,
    graphBaseData,
    zoomChartOpen,
    setZoomChartOpen,
    date_calc,
    mySettings,
    toLocalStringEx,
    detailListOpen,
    listDigClose,
    CloudDistanceDetailColumn
  } = useCloudDistanceDig(props);

  const map = props.mapData.map(item => {
    return {label: item};
  });

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={false} fullWidth>
      <LFCDialogTitle onClose={props.onClose}>{props.pageName}</LFCDialogTitle>
      <DialogContent>
        <ProgressBar startProcess={startProcess} />
        <CloudDistanceDigSearchPanel
          t={t}
          classes={classes}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleChange={handleChange}
          startProcess={startProcess}
          work={work}
          map={map}
          deviceid={deviceid}
          autoOnChange={autoOnChange}
          autoCompleteReset={autoCompleteReset}
          setAutoCompleteReset={setAutoCompleteReset}
          doSearch={doSearch}
          restSearch={restSearch}
          setDetailListOpen={setDetailListOpen}
          searchType={searchType}
          onChangeRadio={onChangeRadio}
        />
        <Box mt={1}>
          {/* 最大値最小値比較 */}
          <CloudDistanceMaxMinComparison
            t={t}
            classes={classes}
            searchValue={searchValue}
            graphBaseData={graphBaseData}
            listData={listData}
            setZoomChartOpen={setZoomChartOpen}
            zoomChartOpen={zoomChartOpen}
            date_calc={date_calc}
          />
          {/* 最大値分析 */}
          <CloudDistanceMaxAnalysis
            t={t}
            classes={classes}
            searchValue={searchValue}
            graphBaseData={graphBaseData}
            listData={listData}
            setZoomChartOpen={setZoomChartOpen}
            zoomChartOpen={zoomChartOpen}
            date_calc={date_calc}
            mySettings={mySettings}
            toLocalStringEx={toLocalStringEx}
          />
          {/* 最小値分析 */}
          <CloudDistanceMinAnalysis
            t={t}
            classes={classes}
            searchValue={searchValue}
            graphBaseData={graphBaseData}
            listData={listData}
            setZoomChartOpen={setZoomChartOpen}
            zoomChartOpen={zoomChartOpen}
            date_calc={date_calc}
            mySettings={mySettings}
            toLocalStringEx={toLocalStringEx}
          />
        </Box>

        <Dialog open={detailListOpen} onClose={listDigClose} maxWidth={false} fullWidth>
          <LFCDialogTitle onClose={listDigClose}>{`${t('menu.page_name.Cloud Distance')}：${t(
            '詳細データ'
          )}`}</LFCDialogTitle>
          <DialogContent>
            <LFCDataGridSimple
              columns={CloudDistanceDetailColumn}
              rows={listData}
              height="80vh"
              exportFilename={`Cloud Distance_${t('詳細データ')}`}
            />
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default CloudDistanceDig;
