import {Card, Grid, Typography, useTheme} from '@mui/material';
import {green} from '@mui/material/colors';
import LFCChartsBoxXYZ3 from '_components/charts/LFCChartsBoxXYZ3';
import {TFunction} from 'i18next';
import {round} from 'lodash';
import LFCSingleValues2 from 'pages/lq-job/_components/LFCSingleValues2';

interface CloudDistanceMaxMinComparisonProps {
  graphBaseData: any;
  searchValue: any;
  zoomChartOpen: any;
  setZoomChartOpen: any;
  classes: any;
  t: TFunction<'translation', undefined, 'translation'>;
  listData: any;
  date_calc: any;
}

export const CloudDistanceMaxMinComparison = ({
  graphBaseData,
  searchValue,
  zoomChartOpen,
  setZoomChartOpen,
  classes,
  t,
  listData,
  date_calc
}: CloudDistanceMaxMinComparisonProps) => {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={2}
      py={'20px'}
      borderBottom={1}
      flexDirection={'column'}
      sx={{
        borderBottomColor: theme.palette.primary.main.replace('rgb', 'rgba').replace(')', ', 0.5)') // RGBをRGBAに変換
      }}
    >
      <Typography padding={2} fontSize={16} fontWeight={700}>
        {t('最大値最小値比較')}
      </Typography>
      <Grid container>
        <Grid item xs={9} padding={2}>
          <LFCChartsBoxXYZ3
            title={t('距離 推移')}
            source={graphBaseData.length > 0 ? graphBaseData[0] : false}
            x={{dsColumn: '', type: 'category'}}
            y={{dsColumn: '', type: 'value'}}
            searchValue={searchValue}
            exportFilename={`CloudDistance_${t('距離推移')}`}
            exportFields={{
              機種: t('機種'),
              検査装置: t('検査装置'),
              検査部位: t('検査部位'),
              検査日時: t('検査日時'),
              最大値_最大: t('最大値　最大'),
              最大値_Q3: t('最大値　Q3'),
              最大値_中央: t('最大値　中央'),
              最大値_Q1: t('最大値　Q1'),
              最大値_最小: t('最大値　最小'),
              最小値_最大: t('最小値　最大'),
              最小値_Q3: t('最小値　Q3'),
              最小値_中央: t('最小値　中央'),
              最小値_Q1: t('最小値　Q1'),
              最小値_最小: t('最小値　最小'),
              上限: t('上限'),
              下限: t('下限')
            }}
            height={'30vh'}
            top={'40'}
            bottom={'70'}
            mapName={searchValue.map[0]}
            xlist={date_calc()}
            zoomChartId={'zoom-chart-1'}
            zoomedChartId={zoomChartOpen}
            zoomCharts={() => setZoomChartOpen('zoom-chart-1')}
            zoomChartsClose={() => setZoomChartOpen('')}
            searchType={searchValue.searchType}
          />
        </Grid>
        <Grid item xs={3} justifyContent={'center'} style={{textAlign: 'center'}} padding={2}>
          <Card
            className={classes.root}
            style={{
              width: '100%',
              padding: '2px',
              marginBottom: '10px'
            }}
          >
            {t('OK範囲基準')}
            <LFCSingleValues2
              columns={
                graphBaseData[0]?.hasMultipleLimit
                  ? [
                      {
                        field: 'colormap_vmax',
                        headerName: t('上限'),
                        width: '100%',
                        unit: ` mm`,
                        formatter: v => round(v, 1),
                        fontColor: green[500]
                      },
                      {
                        field: 'colormap_vmin',
                        headerName: t('下限'),
                        width: '100%',
                        unit: ` mm`,
                        formatter: v => round(v, 1),
                        fontColor: green[500]
                      },
                      {
                        field: 'colormap_appendix',
                        headerName: t('※規格複数のため最新値'),
                        width: '100%',
                        formatter: v => round(v, 1),
                        fontColor: green[500]
                      }
                    ]
                  : [
                      {
                        field: 'colormap_vmax',
                        headerName: t('上限'),
                        width: '100%',
                        unit: ` mm`,
                        formatter: v => round(v, 1),
                        fontColor: green[500]
                      },
                      {
                        field: 'colormap_vmin',
                        headerName: t('下限'),
                        width: '100%',
                        unit: ` mm`,
                        formatter: v => round(v, 1),
                        fontColor: green[500]
                      }
                    ]
              }
              source={{
                colormap_vmax: graphBaseData[0]?.upper_limit,
                colormap_vmin: graphBaseData[0]?.lower_limit
              }}
            />
          </Card>
          <Card
            className={classes.root}
            style={{
              width: '100%',
              padding: '2px',
              marginBottom: '10px'
            }}
          >
            {t('Cloud Distance 検査結果')}
            <LFCSingleValues2
              columns={[
                {
                  field: 'all_count',
                  headerName: t('検査数'),
                  width: '100%',
                  formatter: v => round(v, 1),
                  fontColor: green[500]
                },
                {
                  field: 'ok_count',
                  headerName: t('OK判定'),
                  width: '100%',
                  formatter: v => round(v, 1),
                  fontColor: green[500]
                },
                {
                  field: 'ng_count',
                  headerName: t('NG判定'),
                  width: '100%',
                  formatter: v => round(v, 1),
                  fontColor: green[500]
                },
                {
                  field: 'ng_percent',
                  headerName: t('NG率'),
                  width: '100%',
                  unit: ' %',
                  formatter: v => round(v, 1),
                  fontColor: green[500]
                }
              ]}
              source={{
                all_count: listData.filter((b: any) => b.map === searchValue.map[0]).length,
                ok_count: listData.filter(
                  (b: any) => b.map === searchValue.map[0] && b.total_judge === 0
                ).length,
                ng_count: listData.filter(
                  (b: any) => b.map === searchValue.map[0] && b.total_judge === 1
                ).length,
                ng_percent:
                  listData.filter((b: any) => b.map === searchValue.map[0] && b.total_judge === 0)
                    .length === 0
                    ? 0
                    : (100 *
                        listData.filter(
                          (b: any) => b.map === searchValue.map[0] && b.total_judge === 1
                        ).length) /
                      listData.filter((b: any) => b.map === searchValue.map[0]).length
              }}
            />
          </Card>
          {listData.length > 0 &&
          listData.filter((b: any) => b.map === searchValue.map[0])?.[0]?.hasMultipleLimit
            ? '※規格複数のため最新値'
            : ''}
        </Grid>
      </Grid>
    </Grid>
  );
};
