import LFCSingleValue from '_components/surfaces/LFCSingleValue';
import {green} from '@mui/material/colors';
import {Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CloudDistanceListData} from '../../../types/indes';

interface CloudDistanceSubPanelProps {
  selectedValue: string;
  listData: CloudDistanceListData[];
}

export const CloudDistanceSubPanel = ({selectedValue, listData}: CloudDistanceSubPanelProps) => {
  const {t} = useTranslation();
  return (
    <Grid
      item
      key={'h_' + selectedValue}
      display="flex"
      flexDirection="column"
      xs={2}
      gap={1}
      marginTop={'60px'}
    >
      <LFCSingleValue
        title={t('検査数')}
        val={listData.filter((b: any) => b.map === selectedValue).length}
        width={'200px'}
        titleVariant="caption"
        valVariant="body1"
        key={t('検査数')}
        fontColor={green[500]}
      />
      <LFCSingleValue
        title={t('良品')}
        val={listData.filter((b: any) => b.map === selectedValue && b.total_judge === 0).length}
        width={'200px'}
        titleVariant="caption"
        valVariant="body1"
        key={t('良品')}
        fontColor={green[500]}
      />
      <LFCSingleValue
        title={t('不良品')}
        val={listData.filter((b: any) => b.map === selectedValue && b.total_judge === 1).length}
        width={'200px'}
        titleVariant="caption"
        valVariant="body1"
        key={t('不良品')}
        fontColor={green[500]}
      />

      <LFCSingleValue
        title={t('不良率')}
        val={
          listData.filter((b: any) => b.map === selectedValue && b.total_judge === 0).length === 0
            ? 0
            : (
                (100 *
                  listData.filter((b: any) => b.map === selectedValue && b.total_judge === 1)
                    .length) /
                listData.filter((b: any) => b.map === selectedValue).length
              ).toFixed(2)
        }
        width={'200px'}
        titleVariant="caption"
        valVariant="body1"
        key={t('不良率')}
        fontColor={green[500]}
        unit={' %'}
      />
    </Grid>
  );
};
