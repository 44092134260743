import {Box} from '@mui/material';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LFCButton from '_components/inputs/LFCButton';
import {getLFCData, handleInputChange2} from '_logics/LFCUtil';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCSavedCondition, {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {PAGEID_LQ_JOB_CLOUD_DISTANCE, LOCATIONID_LQ_JOB_CLOUD_DISTANCE} from '_logics/LFCPageId';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import {makeStyles} from 'tss-react/mui';
import {useTranslation} from 'react-i18next';
import {useMySettings} from '_contexts/MySettingsProvider';
import {SearchTypeRadioBtn} from './SearchTypeRadioBtn';
import {CloudDistanceSearchValue} from '../types/indes';
import {useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import {ALL} from '_logics/LFCConst';
import {isEmpty} from 'lodash';
import dayjs from 'dayjs';

interface CloudDistanceSearchPanelProps {
  searchValue: CloudDistanceSearchValue;
  setSearchValue: React.Dispatch<React.SetStateAction<CloudDistanceSearchValue>>;
  handleSearch: () => void;
  resetSearch: () => void;
  resetResults: () => void;
  setStartProcess: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CloudDistanceSearchPanel = ({
  searchValue,
  setSearchValue,
  handleSearch,
  resetSearch,
  resetResults,
  setStartProcess
}: CloudDistanceSearchPanelProps) => {
  const [openSavedCondition, setOpenSavedCondition] = useState<boolean>(false);
  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const [isAutoCompleteReset, setIsAutoCompleteReset] = useState<boolean>(false);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_date_from',
        value: searchValue.select_date_from,
        valueLabel:
          searchValue.select_date_from === ''
            ? ''
            : dayjs(searchValue.select_date_from).format('YYYY-MM-DD HH:mm:ss'),
        colName: t('対象期間From'),
        colWidth: 200
      },
      {
        name: 'select_date_to',
        value: searchValue.select_date_to,
        valueLabel:
          searchValue.select_date_to === ''
            ? ''
            : dayjs(searchValue.select_date_to).format('YYYY-MM-DD HH:mm:ss'),
        colName: t('対象期間To'),
        colWidth: 200
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: !isEmpty(searchValue.work) ? searchValue.work.join(',') : '',
        colName: t('機種'),
        colWidth: 200
      },
      {
        name: 'deviceid',
        value: searchValue.deviceid,
        valueLabel: !isEmpty(searchValue.deviceid) ? searchValue.deviceid.join(',') : '',
        colName: t('検査装置'),
        colWidth: 200
      },
      {
        name: 'judge',
        value: searchValue.judge,
        valueLabel: searchValue.judge === 9 ? ALL : searchValue.judge === 0 ? 'OK' : 'NG',
        colName: t('総合判定'),
        colWidth: 100
      },
      {
        name: 'searchType',
        value: searchValue.searchType,
        valueLabel:
          searchValue.searchType === 0
            ? t('検査ごと')
            : searchValue.searchType === 1
              ? t('1時間ごと')
              : searchValue.searchType === 2
                ? t('1日ごと')
                : searchValue.searchType === 3
                  ? t('1ヶ月ごと')
                  : '',
        colName: t('集計単位'),
        colWidth: 100
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: ConditionParamer[]) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setIsAutoCompleteReset(true);
  };
  const useStyles = makeStyles()(theme => {
    return {
      fixedDarkHeader: {
        position: 'fixed',
        top: 60,
        left: 0,
        right: 0,
        zIndex: 1000,
        padding: theme.spacing(2),
        backgroundColor: 'rgb(26, 26, 26)',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
      },
      fixedLightHeader: {
        position: 'fixed',
        top: 60,
        left: 0,
        right: 0,
        zIndex: 1000,
        padding: theme.spacing(2),
        backgroundColor: 'rgb(242, 242, 242)',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)'
      },
      content: {
        marginTop: '200px',
        [theme.breakpoints.down('sm')]: {
          marginTop: '250px'
        },
        [theme.breakpoints.down('xs')]: {
          marginTop: '250px'
        }
      }
    };
  });
  const mySettings = useMySettings();
  const {classes} = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const autoOnChange = (relayDatas: {name: string; data: string}) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };
  const handleChange = (event: {target: {name: string; value: string}}) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  useEffect(() => {
    setStartProcess(true);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42009,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: {work: string}) => {
          return {label: item['work']};
        });
        setWork(tmp);
      })
      .catch(e => e);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42010,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: {deviceid: string}) => {
          return {label: item['deviceid']};
        });
        setDeviceid(tmp);
      })
      .then(() => setStartProcess(false))
      .catch(e => e);
    return () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  return (
    <Box className={mySettings.isDark ? classes.fixedDarkHeader : classes.fixedLightHeader}>
      <LFCFormRowGroup>
        <LFCDatetimePicker
          name={t('select_date_from')}
          label={t('対象期間From')}
          value={searchValue.select_date_from}
          required
          onChange={handleChange}
        />
        <LFCDatetimePicker
          name={t('select_date_to')}
          label={t('対象期間To')}
          value={searchValue.select_date_to}
          required
          onChange={handleChange}
        />
        <LFCAutocomplete
          name={'work'}
          label={t('機種')}
          id={'work'}
          value={multiSelectData(work, searchValue.work)}
          onChange={autoOnChange}
          onReset={isAutoCompleteReset}
          doneReset={setIsAutoCompleteReset}
          multiple={true}
          selectItem={work}
        />
        <LFCAutocomplete
          name={'deviceid'}
          label={t('検査装置')}
          id={'deviceid'}
          value={multiSelectData(deviceid, searchValue.deviceid)}
          onChange={autoOnChange}
          multiple={true}
          onReset={isAutoCompleteReset}
          doneReset={setIsAutoCompleteReset}
          selectItem={deviceid}
        />
        <LFCSelectFormJudge
          name={'judge'}
          value={searchValue.judge}
          onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
        />
        <LFCButton color="primary" onClick={handleSearch}>
          {t('検索')}
        </LFCButton>
        <LFCButton onClick={resetSearch}>{t('リセット')}</LFCButton>
        <LFCSavedCondition
          open={openSavedCondition}
          pageId={PAGEID_LQ_JOB_CLOUD_DISTANCE}
          locationNo={LOCATIONID_LQ_JOB_CLOUD_DISTANCE}
          onLoad={onLoadSavedCondition}
          onSelect={onSelectSavedCondition}
          onClose={() => setOpenSavedCondition(false)}
          conditions={condition}
        />
      </LFCFormRowGroup>
      <SearchTypeRadioBtn
        searchValue={searchValue}
        resetResults={resetResults}
        setSearchValue={setSearchValue}
      />
    </Box>
  );
};
