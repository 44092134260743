import {Grid} from '@mui/material';
import LFCChartsBoxXYZ2 from '_components/charts/LFCChartsBoxXYZ2';
import {
  CloudDistanceListData,
  CloudDistanceSearchValue
} from 'pages/lq-job/cloud-distance/types/indes';
import {useTranslation} from 'react-i18next';

interface CloudDistanceGraphPanelProps {
  selectedValue: string;
  listData: CloudDistanceListData[];
  graphBaseData: any;
  searchValue: CloudDistanceSearchValue;
  graphClick: (prm: {data: any}) => void;
  dateCalc: () => void;
  zoomChartOpen: string;
  setZoomChartOpen: React.Dispatch<React.SetStateAction<string>>;
}

export const CloudDistanceGraphPanel = ({
  selectedValue,
  listData,
  graphBaseData,
  searchValue,
  graphClick,
  dateCalc,
  zoomChartOpen,
  setZoomChartOpen
}: CloudDistanceGraphPanelProps) => {
  const {t} = useTranslation();
  const listData_column = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    x0: `${t('X座標誤差')} ${t('最大')}`,
    x1: `${t('X座標誤差')} ${t('Q3')}`,
    x2: `${t('X座標誤差')} ${t('中央(orQ2)')}`,
    x3: `${t('X座標誤差')} ${t('Q1')}`,
    x4: `${t('X座標誤差')} ${t('最小')}`,
    y0: `${t('Y座標誤差')} ${t('最大')}`,
    y1: `${t('Y座標誤差')} ${t('Q3')}`,
    y2: `${t('Y座標誤差')} ${t('中央(orQ2)')}`,
    y3: `${t('Y座標誤差')} ${t('Q1')}`,
    y4: `${t('Y座標誤差')} ${t('最小')}`
  };
  const listData_column_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    data_upper_distance: t('最大値'),
    data_lower_distance: t('最小値'),
    serial: t('シリアルナンバー')
  };
  return (
    <Grid item xs={12} md={10} key={'g_' + selectedValue}>
      {t('部位名')}:{selectedValue}
      {graphBaseData.filter((data: any) => data.map === selectedValue)[0] && (
        <LFCChartsBoxXYZ2
          title=""
          source={
            graphBaseData.length > 0
              ? graphBaseData.filter((data: any) => data.map === selectedValue)[0]
              : false
          }
          x={{dsColumn: '', type: 'category'}}
          y={{dsColumn: '', type: 'value'}}
          exportData={listData.filter((datas: any) => datas.map === selectedValue)}
          exportFields={searchValue.searchType !== 0 ? listData_column : listData_column_type0}
          searchType={searchValue.searchType}
          exportFilename={`Cloud Distance_` + selectedValue}
          onClick={graphClick}
          height={'30vh'}
          top={'40'}
          bottom={'70'}
          mapName={selectedValue}
          xlist={dateCalc()}
          isShowDetailAnalysis={true}
          onClickDetailAnalysis={() =>
            graphClick(graphBaseData.filter((data: any) => data.map === selectedValue)[0])
          }
          zoomChartId={'zoom-chart-2'}
          zoomedChartId={zoomChartOpen}
          zoomCharts={() => setZoomChartOpen('zoom-chart-2')}
          zoomChartsClose={() => setZoomChartOpen('')}
        />
      )}
    </Grid>
  );
};
