import {CustomSelect} from '../../CustomSelect';
import {Box, Grid} from '@mui/material';
import {CloudDistanceSubPanel} from './components/CloudDistanceSubPanel';
import {CloudDistanceGraphPanel} from './components/CloudDistanceGraphPanel';
import {useState} from 'react';

interface CloudDistanceMainPanelProps {
  classes: any;
  mapData: any;
  selectedValues: any;
  setSelectedValues: any;
  graphClick: any;
  dateCalc: any;
  graphBaseData: any;
  listData: any;
  startProcess: any;
  t: any;
  searchValue: any;
}

export const CloudDistanceMainPanel = ({
  classes,
  mapData,
  selectedValues,
  setSelectedValues,
  listData,
  startProcess,
  t,
  dateCalc,
  searchValue,
  graphBaseData,
  graphClick
}: CloudDistanceMainPanelProps) => {
  const [zoomChartOpen, setZoomChartOpen] = useState<string>('');
  return (
    <Box mt={1} display="flex" flexDirection="row" gap={4} className={classes.content}>
      <CustomSelect
        mapSeries={mapData}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        startProcess={startProcess}
        t={t}
      />
      <Grid container spacing={2} md={12} marginLeft={40} item>
        {selectedValues.map((selectedValue: string, index: number) => {
          return (
            <Grid container spacing={2} key={index} marginRight={'50px'}>
              <CloudDistanceGraphPanel
                dateCalc={dateCalc}
                selectedValue={selectedValue}
                listData={listData}
                graphBaseData={graphBaseData}
                searchValue={searchValue}
                graphClick={graphClick}
                zoomChartOpen={zoomChartOpen}
                setZoomChartOpen={setZoomChartOpen}
              />
              <CloudDistanceSubPanel selectedValue={selectedValue} listData={listData} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
