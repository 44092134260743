import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import LFCChartsBarWorkCloudDistance2 from '_components/charts/LFCChartsBarWorkCloudDistance2';
import LFCChartsBoxXYZ8 from '_components/charts/LFCChartsBoxXYZ8';
import LFCChartsBoxXYZ9 from '_components/charts/LFCChartsBoxXYZ9';
import {TFunction} from 'i18next';
import {useEffect, useState} from 'react';

interface CloudDistanceMinAnalysisProps {
  graphBaseData: any;
  searchValue: any;
  zoomChartOpen: any;
  setZoomChartOpen: any;
  classes: any;
  t: TFunction<'translation', undefined, 'translation'>;
  listData: any;
  date_calc: any;
  mySettings: any;
  toLocalStringEx: any;
}

export const CloudDistanceMinAnalysis = ({
  graphBaseData,
  searchValue,
  zoomChartOpen,
  setZoomChartOpen,
  classes,
  t,
  listData,
  date_calc,
  mySettings,
  toLocalStringEx
}: CloudDistanceMinAnalysisProps) => {
  const filteredData = listData
    .filter((a: any) => a.map === searchValue.map[0])
    .map((b: any) => b.lower_distance)
    .flat()
    .filter((value: any) => value.length !== 0); // 空の配列を除外

  const maxUpperDistance = Math.max(...filteredData);
  const minUpperDistance = Math.min(...filteredData);

  const averageUpperDistance =
    filteredData.reduce((sum: number, value: number) => sum + value, 0) / filteredData.length;

  const medianUpperDistance = (() => {
    const sorted = [...filteredData].sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);
    return sorted.length % 2 !== 0 ? sorted[middle] : (sorted[middle - 1] + sorted[middle]) / 2;
  })();
  const variance =
    filteredData.reduce(
      (sum: number, value: number) => sum + Math.pow(value - averageUpperDistance, 2),
      0
    ) / filteredData.length;
  const standardDeviation = Math.sqrt(variance);

  const averagePlus3SD = averageUpperDistance + 3 * standardDeviation;
  const averageMinus3SD = averageUpperDistance - 3 * standardDeviation;
  const upperLimit = listData.filter((a: any) => a.map === searchValue.map[0])[0]?.upper_limit;
  const lowerLimit = listData.filter((a: any) => a.map === searchValue.map[0])[0]?.lower_limit;
  const [xLineMax, setXLineMax] = useState(0);
  const [xRenge, setXRenge] = useState(0);

  const cp = (upperLimit - lowerLimit) / (6 * standardDeviation);

  const val1 = Math.abs(upperLimit - averageUpperDistance) / (3 * standardDeviation);
  const val2 = Math.abs(averageUpperDistance - lowerLimit) / (3 * standardDeviation);
  const cpk = Math.min(val1, val2);
  useEffect(() => {
    let tmp_n: number = filteredData.length;
    let tmp_k: number;
    if (Math.floor(Math.log2(tmp_n) + 1) <= 7) {
      tmp_k = 7;
    } else if (Math.floor(Math.log2(tmp_n) + 1) >= 27) {
      tmp_k = 27;
    } else {
      tmp_k = Math.floor(Math.log2(tmp_n) + 1);
    }
    // 「最大値」「上限(仕様)」「管理限界線(+3σ)」
    let tmp_max: number = upperLimit;

    setXLineMax(tmp_max);
    let tmp_min: number = lowerLimit;

    let tmp_h: number = (tmp_max - tmp_min) / tmp_k;
    let tmp_dot: number = String(tmp_h).indexOf('.');
    let tmp_round: number;
    if (tmp_dot > 0) {
      tmp_round = Math.floor(tmp_h * (tmp_dot * 100)) / (tmp_dot * 100);
    } else {
      tmp_round = Math.floor(tmp_h);
    }
    setXRenge(tmp_round);
  }, [filteredData]);
  return (
    <Grid container spacing={2} py={'20px'} flexDirection={'column'}>
      <Typography padding={2} fontSize={16} fontWeight={700}>
        {t('最小値分析')}
      </Typography>
      <Grid container>
        <Grid item xs={12} md={6} padding={2}>
          <LFCChartsBoxXYZ8
            title={t('最小値 推移')}
            source={graphBaseData.length > 0 ? graphBaseData[0] : false}
            x={{dsColumn: '', type: 'category'}}
            y={{dsColumn: '', type: 'value'}}
            searchValue={searchValue}
            exportFilename={`Cloud Distance_` + searchValue.map[0]}
            height={'30vh'}
            top={'40'}
            bottom={'70'}
            mapName={searchValue.map[0]}
            xlist={date_calc()}
            zoomChartId={'zoom-chart-5'}
            zoomedChartId={zoomChartOpen}
            zoomCharts={() => setZoomChartOpen('zoom-chart-5')}
            zoomChartsClose={() => setZoomChartOpen('')}
            exportData={[]}
            averageUpperDistance={averageUpperDistance}
            medianUpperDistance={medianUpperDistance}
            averagePlus3SD={averagePlus3SD}
            averageMinus3SD={averageMinus3SD}
            searchType={searchValue.searchType}
          />
        </Grid>
        <Grid item xs={12} md={6} padding={2}>
          <LFCChartsBarWorkCloudDistance2
            title={`${t('最小値 分布')}`}
            source={graphBaseData.length > 0 ? graphBaseData[0] : false}
            exportFilename={`CloudDistance_${t('最小値ばらつき')}`}
            xAxisName={`(${t('個')})`}
            height={'25vh'}
            bottom={'35%'}
            onClick={() => {}}
            zoomChartId={'zoom-chart-6'}
            zoomedChartId={zoomChartOpen}
            zoomCharts={() => setZoomChartOpen('zoom-chart-6')}
            zoomChartsClose={() => setZoomChartOpen('')}
            xLineMax={xLineMax}
            xRenge={xRenge}
            averageMinus3SD={averageMinus3SD}
            averagePlus3SD={averagePlus3SD}
            averageUpperDistance={averageUpperDistance}
            medianUpperDistance={medianUpperDistance}
          />
        </Grid>
        <Grid item xs={12} md={6} padding={2}>
          <LFCChartsBoxXYZ9
            title={t('最小値　ばらつき推移')}
            source={listData}
            xData={date_calc()}
            color={['rgb(106,113,255)']}
            exportFilename={`CloudDistance_${t('最小値ばらつき')}`}
            zoomChartId={'zoom-chart-7'}
            zoomedChartId={zoomChartOpen}
            zoomCharts={() => setZoomChartOpen('zoom-chart-7')}
            zoomChartsClose={() => setZoomChartOpen('')}
            searchValue={searchValue}
          />
        </Grid>
        <Grid item xs={12} md={6} padding={2}>
          <TableContainer
            component={Paper}
            className={mySettings.isDark ? classes.tableLineDark : classes.tableLine}
          >
            <Table sx={{minWidth: 600}} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    {t('規格')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('実績')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('傾向ばらつき管理')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('工程能力')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{t('上限')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(
                      listData.filter((a: any) => a.map === searchValue.map[0])[0]?.upper_limit
                    )}
                    mm
                  </TableCell>
                  <TableCell align="center">{t('最大')}</TableCell>
                  <TableCell align="center">{toLocalStringEx(maxUpperDistance)} mm</TableCell>
                  <TableCell align="center">{t('平均+3σ')}</TableCell>
                  <TableCell align="center">{toLocalStringEx(averagePlus3SD)} mm</TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cp')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(cp)}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" rowSpan={2}>
                    {t('目標')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    -
                  </TableCell>
                  <TableCell align="center">{t('平均')}</TableCell>
                  <TableCell align="center">{toLocalStringEx(averageUpperDistance)} mm</TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('標準偏差σ')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(standardDeviation)} mm
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('中央')}</TableCell>
                  <TableCell align="center">{toLocalStringEx(medianUpperDistance)} mm</TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cpk')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(cpk)}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('下限')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(
                      listData.filter((a: any) => a.map === searchValue.map[0])[0]?.lower_limit
                    )}
                    mm
                  </TableCell>
                  <TableCell align="center">{t('最小')}</TableCell>
                  <TableCell align="center">{toLocalStringEx(minUpperDistance)} mm</TableCell>
                  <TableCell align="center">{t('平均-3σ')}</TableCell>
                  <TableCell align="center">{toLocalStringEx(averageMinus3SD)} mm</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Typography align="center">
            {listData.length > 0 &&
            listData.filter((b: any) => b.map === searchValue.map[0])?.[0]?.hasMultipleLimit
              ? '※規格複数のため最新値'
              : ''}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
