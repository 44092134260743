import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import LFCChartsInputScaleDialog, {
  initialScaleValue,
  ScaleValue
} from './components/LFCChartsInputScale';
import {useTranslation} from 'react-i18next';
import {SearchValue} from 'types/weldingDemo';
import {fmtYYYYMMDDHHmmss} from '_logics/LFCFormatUtil';
import dayjs from 'dayjs';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  xAxisName?: string;
  yAxisName?: string;
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  top?: string;
  onClick?: (prm: {data: any}) => void;
  mapName?: string;
  refJobTitle?: string;
  parameterName?: string;
  inputScale?: boolean;
  xlist: any;
  isShowDetailAnalysis?: boolean;
  onClickDetailAnalysis?: any;
  zoomStatus?: boolean;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  searchValue: any;
  searchType: any;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBoxXYZ3 = (props: Props) => {
  const {t} = useTranslation();
  const [scaleValue, setScaleValue] = useState<ScaleValue>(initialScaleValue);
  const [inputScaleOpen, setInputScaleOpen] = useState(false);
  const [boxData, setBoxData] = useState([]);
  // スケール変更
  const onApply = (scaleValue: ScaleValue) => {
    setScaleValue(scaleValue);
  };

  const makeBoxData = () => {
    //ボックスデータの算出
    const CalcQuartile = (data: any) => {
      let max = Math.max(...data.map((a: any) => Number(a)));
      let min = Math.min(...data.map((a: any) => Number(a)));

      const dataQ1 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.25;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ2 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.5;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ3 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.75;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };
      return [min, dataQ1(), dataQ2(), dataQ3(), max];
    };

    let tmp_upper_distance: any = [];
    let tmp_lower_distance: any = [];
    let tmpOutPut: any = [];
    tmpOutPut[0] = [];
    tmpOutPut[1] = [];

    if (!isEmpty(props.xlist)) {
      props.xlist.forEach((a: string) => {
        if (props.source.xlist.find((k: string) => k === a)) {
          props.source.xlist.find((b: string, index: number) => {
            if (b === a) {
              tmp_upper_distance.push(props.source.data_upper_distance[index]);
              tmp_lower_distance.push(props.source.data_lower_distance[index]);
            }
          });
        } else {
          tmp_upper_distance.push([]);
          tmp_lower_distance.push([]);
        }
      });

      tmp_upper_distance.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmpOutPut[0].push(CalcQuartile(a));
        } else {
          tmpOutPut[0].push([]);
        }
      });
      tmp_lower_distance.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmpOutPut[1].push(CalcQuartile(a));
        } else {
          tmpOutPut[1].push([]);
        }
      });
    }
    setBoxData(tmpOutPut);
  };

  const [exportData, setExportData] = useState([]);

  useEffect(() => {
    makeBoxData();
  }, [props]);

  useEffect(() => {
    if (boxData.length === 0) return;
    if (props?.searchType === 0) return;
    const _exportData = props?.source?.xlist.map((date: any, i: number) => {
      if (!Array.isArray(boxData[0][i])) return [];
      if ((boxData[0][i] as any[]).length === 0 || (boxData[1][i] as any).length === 0) return [];
      return {
        機種: props.searchValue.work.join(','),
        検査装置: props.searchValue.deviceid.join(','),
        検査部位: props.mapName,
        検査日時: fmtYYYYMMDDHHmmss(props.source.lq_time),
        最大値_最大: boxData[0][i][4],
        最大値_Q3: boxData[0][i][3],
        最大値_中央: boxData[0][i][2],
        最大値_Q1: boxData[0][i][1],
        最大値_最小: boxData[0][i][0],
        最小値_最大: boxData[1][i][4],
        最小値_Q3: boxData[1][i][3],
        最小値_中央: boxData[1][i][2],
        最小値_Q1: boxData[1][i][1],
        最小値_最小: boxData[1][i][0],
        上限: props.source.upper_limit,
        下限: props.source.lower_limit
      };
    });
    setExportData(_exportData);
  }, [boxData, props.searchType]);

  return (
    <div>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          legend: {
            id: 1,
            type: 'scroll',
            itemWidth: 10,
            show: true,
            top: '90%',
            textStyle: {
              fontSize: 10
            },
            data:
              props.searchType === 0
                ? [t('最大値'), t('最小値'), t('上限'), t('下限')]
                : [t('最大値箱ひげ図'), t('最小値箱ひげ図'), t('上限'), t('下限')],
            selected: {
              上限: true,
              下限: true
            }
          },
          tooltip: {
            trigger: 'axis', // 軸に沿ってtooltipを表示するために'axis'を使用
            formatter: function (params: any) {
              if (props.searchType === 0) {
                return (
                  t('連番') +
                  ':' +
                  params[0].name +
                  '<br/>' +
                  t('シリアルナンバー') +
                  ':' +
                  props.source.uuid +
                  '<br/>' +
                  t('検査日時') +
                  ':' +
                  dayjs(props.source.lq_time).format('YYYY-MM-DD HH:mm:ss') +
                  '<br/>' +
                  t('最大値') +
                  ':' +
                  (isNaN(params[0].value) ? 'データなし' : Number(params[0].value).toFixed(2)) +
                  '<br/>' +
                  t('最小値') +
                  ':' +
                  (isNaN(params[1].value) ? 'データなし' : Number(params[1].value).toFixed(2)) +
                  '<br/>' +
                  '----' +
                  '<br/>' +
                  t('上限') +
                  ':' +
                  (isNaN(params[2].value) ? 'データなし' : Number(params[2].value).toFixed(2)) +
                  '<br/>' +
                  t('下限') +
                  ':' +
                  (isNaN(params[3].value) ? 'データなし' : Number(params[3].value).toFixed(2))
                );
              }
              return (
                params[0].name +
                ':' +
                t('最大値') +
                '<br/>' +
                t('最大') +
                ':' +
                (isNaN(params[0].value[5]) ? 'データなし' : Number(params[0].value[5]).toFixed(2)) +
                '<br/>' +
                'Q3：' +
                (isNaN(params[0].value[4]) ? 'データなし' : Number(params[0].value[4]).toFixed(2)) +
                '<br/>' +
                t('中央') +
                ' (or Q2)：' +
                (isNaN(params[0].value[3]) ? 'データなし' : Number(params[0].value[3]).toFixed(2)) +
                '<br/>' +
                'Q1：' +
                (isNaN(params[0].value[2]) ? 'データなし' : Number(params[0].value[2]).toFixed(2)) +
                '<br/>' +
                t('最小') +
                ':' +
                (isNaN(params[0].value[1]) ? 'データなし' : Number(params[0].value[1]).toFixed(2)) +
                '<br/>' +
                '----' +
                '<br/>' +
                t('上限') +
                ':' +
                (isNaN(params[2].value) ? 'データなし' : Number(params[2].value).toFixed(2)) +
                '<br/>' +
                t('下限') +
                ':' +
                (isNaN(params[3].value) ? 'データなし' : Number(params[3].value).toFixed(2))
              );
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '10%',
            right: '8%',
            bottom: props.bottom,
            top: props.top
          },
          xAxis: {
            type: 'category',
            name: props.xAxisName !== undefined ? props.xAxisName : '',
            data: !isEmpty(props.xlist) ? props.xlist : props.source.xlist,
            nameTextStyle: {
              fontSize: 10
            },
            nameLocation: 'end',
            splitLine: {
              show: true
            },
            axisLabel: {
              rotate: '15',
              fontSize: 10
            }
          },
          yAxis: {
            type: 'value',
            name: props.yAxisName !== undefined ? props.yAxisName : '',
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              interval: 0,
              fontSize: 10
            },
            interval: scaleValue.y1AxisInterval
          },
          series:
            props.searchValue.searchType === 0
              ? [
                  {
                    name: t('最大値'),
                    type: 'line',
                    data: props.source.data_upper_distance,
                    color: 'rgb(243,6,6)'
                  },
                  {
                    name: t('最小値'),
                    type: 'line',
                    data: props.source.data_lower_distance,
                    color: 'rgb(0,0,255)'
                  },
                  {
                    name: t('上限'),
                    type: 'line',
                    color: '#FF1493',
                    data: props.source.data_upper_limits.map((e: any) => {
                      return e ?? null;
                    }),
                    symbolSize: 1,
                    connectNulls: true // null を無視して線をつなぐ
                  },
                  {
                    name: t('下限'),
                    type: 'line',
                    color: '#FF4500',
                    data: props.source.data_lower_limits.map((e: any) => {
                      return e ?? null;
                    }),
                    connectNulls: true,
                    symbolSize: 1
                  }
                ]
              : [
                  {
                    name: t('最大値箱ひげ図'),
                    type: 'boxplot',
                    data: boxData[0],
                    color: 'rgb(243,6,6)'
                  },
                  {
                    name: t('最小値箱ひげ図'),
                    type: 'boxplot',
                    data: boxData[1],
                    color: 'rgb(0,0,255)'
                  },
                  {
                    name: t('上限'),
                    type: 'line',
                    color: '#FF1493',
                    data: props.source.data_upper_limits.map((e: any) => {
                      return e?.[0] ?? null;
                    }),
                    connectNulls: true,
                    symbolSize: 1
                  },
                  {
                    name: t('下限'),
                    type: 'line',
                    color: '#FF4500',
                    data: props.source.data_lower_limits.map((e: any) => {
                      return e?.[0] ?? null;
                    }),
                    connectNulls: true,
                    symbolSize: 1
                  }
                ]
        }}
        exportData={exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : '100px'}
        onEvents={() => {}}
        isShowDetailAnalysis={props.isShowDetailAnalysis ?? false}
        onClickDetailAnalysis={props.onClickDetailAnalysis ?? undefined}
        zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
        zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
        zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
        zoomStatus={props.zoomChartId === props.zoomedChartId}
        inputScaleStatus={props.inputScale !== undefined ? props.inputScale : false}
        inputScaleOpen={() => setInputScaleOpen(true)}
      />
      <LFCChartsInputScaleDialog
        open={inputScaleOpen}
        onClose={() => setInputScaleOpen(false)}
        onApply={onApply}
        y1AxisName={props.yAxisName}
        inputY1Axis={true}
      />
    </div>
  );
};
export default LFCChartsBoxXYZ3;
