import ProgressBar from '_components/ProgressBar';
import GenericTemplate from '_templates/GenericTemplate';

import {useCloudDistance} from './hooks/useCloudDistance';
import {CloudDistanceSearchPanel} from './components/CloudDistanceSearchPanel';
import {CloudDistanceMainPanel} from './components/cloud-distance-main-panel';
import CloudDistanceDig from './components/cloud-distance-dig';

export const CloudDistancePage = () => {
  const {
    startProcess,
    t,
    searchValue,
    handleSearch,
    resetSearch,
    open,
    openData,
    handleClose,
    classes,
    mapData,
    selectedValues,
    setSelectedValues,
    graphClick,
    dateCalc,
    graphBaseData,
    listData,
    setSearchValue,
    resetResults,
    setStartProcess
  } = useCloudDistance();
  const title = 'menu.tab_name.L-Qジョブ傾向分析';
  const description = 'menu.page_name.Cloud Distance';

  return (
    <GenericTemplate title={`${t(title)}：${t(description)}`}>
      <ProgressBar startProcess={startProcess} screenLock={true} />
      <CloudDistanceSearchPanel
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handleSearch={handleSearch}
        resetSearch={resetSearch}
        resetResults={resetResults}
        setStartProcess={setStartProcess}
      />
      <CloudDistanceMainPanel
        classes={classes}
        mapData={mapData}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        graphClick={graphClick}
        dateCalc={dateCalc}
        graphBaseData={graphBaseData}
        listData={listData}
        startProcess={startProcess}
        t={t}
        searchValue={searchValue}
      />
      <CloudDistanceDig
        pageName={`${t('menu.page_name.Cloud Distance')}: ${t('詳細')}`}
        open={open}
        onClose={handleClose}
        datas={openData}
        searchValue={searchValue}
        mapData={mapData}
      />
    </GenericTemplate>
  );
};
