import {FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import {CloudDistanceSearchType, CloudDistanceSearchValue} from '../types/indes';
import {useTranslation} from 'react-i18next';

interface SearchTypeRadioBtnProps {
  searchValue: CloudDistanceSearchValue;
  setSearchValue: React.Dispatch<React.SetStateAction<CloudDistanceSearchValue>>;
  resetResults: () => void;
}

export const SearchTypeRadioBtn = ({
  searchValue,
  setSearchValue,
  resetResults
}: SearchTypeRadioBtnProps) => {
  const {t} = useTranslation();
  const searchType: CloudDistanceSearchType[] = [
    {id: 0, text: t('検査ごと')},
    {id: 1, text: t('1時間ごと')},
    {id: 2, text: t('1日ごと')},
    {id: 3, text: t('1ヶ月ごと')}
  ];
  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue((prevValue: CloudDistanceSearchValue) => ({
      ...prevValue,
      searchType: Number(e.target.value)
    }));
    resetResults();
  };

  return (
    <>
      <Typography style={{float: 'left', margin: 10, paddingRight: 10}}>
        {t('集計単位')}:
      </Typography>
      <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
        {searchType.map((item: any) => (
          <FormControlLabel
            value={item.id}
            key={'search_type-' + item.id}
            control={
              <Radio onChange={onChangeRadio} checked={item.id === searchValue.searchType} />
            }
            label={
              <Typography display="block" key={'search_type-body-' + item.id}>
                {item.text}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </>
  );
};
